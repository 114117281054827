import './termsandconpol.css'
const TermsAndConPol=()=>{
    return(
        <>
        <div className="termsandcon extraspace">
            {/* <h1 style={{paddingTop: "4pt",paddingLeft:"5pt",textIndent:"0pt",textAlign: "left"}}>Terms and Conditions</h1> */}
        <p className="extraspace" style={{paddingTop: "14pt",paddingLeft: "3.5pt",textIndent: "0pt",textAlign:"left"}}>Introduction These terms and conditions (&quot;Terms&quot;) govern the use of the Wareeg platform (&quot;Platform&quot;) by users (&quot;Users&quot;). The Platform is a peer-to-peer marketplace that facilitates experiencers (&quot;Guests&quot;) to book locals (&quot;Hosts&quot;) to host a private experience anytime, anywhere. By accessing or using the Platform, Users agree to be bound by these Terms. These Terms constitute a legal agreement between Users and Wareeg (&quot;we&quot;, &quot;us&quot;, or&quot;our&quot;).</p>
        {/* <p style={{textIndent: "0pt",textAlign: "left"}}> */}
            {/* <br/>
        </p> */}
        <ol id="tl1" className="extraspace">
            <li data-list-text="1." className="extraspace">
                <h4 className="extraheading2" style={{paddingLeft: "20pt",textIndent: "-18pt",textAlign: "left",color:"#4189DD",fontWeight:"600",fontSize:"18px"}}>Definitions</h4>
                <ol id="tl2">
                    <li className="extraspace" data-list-text="1.1.">
                        <p className="extraspace" style={{paddingLeft: "2pt",textAlign: "justify"}}>&quot;Experiencers&quot;refer to Guests who use the Platform to book an Experience with a Host.</p>
                    </li>
                    <li data-list-text="1.2."className="extraspace" >
                        <p className="extraspace" style={{paddingLeft: "2pt",textAlign: "justify"}}>&quot;Locals&quot;refer to Hosts who use the Platform to offer an Experience to Guests.</p>
                    </li>
                    <li data-list-text="1.3.">
                        <p className="extraspace" style={{paddingLeft: "2pt",lineHeight: "16pt",textAlign: "justify"}}>“Platform” refer to the Wareeg website and mobile application, and any related services, features, and content.</p>
                    </li>
                </ol>
            </li>
            <li data-list-text="2.">
                <h4 className="extraheading2" style={{paddingLeft: "20pt",textIndent:"-18pt",textAlign: "left",color:"#4189DD",fontWeight:"600",fontSize:"18px"}}>Use of the Platform</h4>
                <ol id="tl3">
                    <li data-list-text="2.1." >
                        <p className="extraspace" style={{paddingLeft: "2pt",textAlign:"justify"}}>Users must be at least 18 years of age to use the Platform.</p>
                    </li>
                    <li data-list-text="2.2.">
                        <p className="extraspace" style={{paddingLeft: "2pt",textAlign: "justify"}}>Users agree to provide accurate and complete information during registration and to keep their profile information up-to-date.</p>
                    </li>
                    <li data-list-text="2.3.">
                        <p className="extraspace" style={{paddingLeft: "2pt",textAlign: "justify"}}>Users are responsible for maintaining the confidentiality of their account information and for any activity that occurs under their account.</p>
                    </li>
                    <li data-list-text="2.4.">
                        <p className="extraspace" style={{paddingLeft: "2pt",textAlign: "justify"}}>Users may not use the Platform for any unlawful or fraudulent activity.</p>
                    </li>
                    <li data-list-text="2.5.">
                        <p className="extraspace" style={{paddingLeft: "2pt",textAlign: "justify"}}>Users agree to comply with all applicable laws and regulations, including but not limited to GDPR and CCA.</p>
                    </li>
                    <li data-list-text="2.6.">
                        <p className="extraspace" style={{paddingLeft: "2pt",textAlign: "justify"}}>Users agree to respect the privacy and personal data of other Users and to comply with our Privacy Policy.</p>
                    </li>
                    <li data-list-text="2.7.">
                        <p className="extraspace" style={{paddingLeft: "2pt",textAlign: "justify"}}>Users agree not to use the Platform to harass, defame, or discriminate against other Users.</p>
                    </li>
                </ol>
            </li>
            <li data-list-text="3.">
                <h4 className="extraheading2" style={{paddingLeft: "20pt",textIndent: "-18pt",textAlign: "left",color:"#4189DD",fontWeight:"600",fontSize:"18px"}}>Safety</h4>
                <ol id="tl4">
                    <li data-list-text="3.1.">
                        <p className="extraspace" style={{paddingLeft:"2pt",textAlign:"justify"}}>Users are responsible for their own safety during an Experience.</p>
                    </li>
                    <li data-list-text="3.2.">
                        <p className="extraspace" style={{paddingLeft: "2pt",textAlign: "justify"}}>Hosts are not permitted to offer or accept transportation of any kind. Users should have their own transportation.</p>
                    </li>
                    <li data-list-text="3.3.">
                        <p className="extraspace" style={{paddingLeft: "2pt",textAlign: "justify"}}>Hosts are responsible for ensuring that their Experience complies with all applicable laws and regulations related to safety.</p>
                    </li>
                </ol>
            </li>
            <li data-list-text="4.">
                <h4  className="extraheading2" style={{paddingLeft: "20pt",textIndent: "-18pt",textAlign: "left",color:"#4189DD",fontWeight:"600",fontSize:"18px"}}>Booking and Payment</h4>
                <ol id="tl5">
                    <li data-list-text="4.1.">
                        <p className="extraspace" style={{paddingLeft: "2pt",textAlign: "justify"}}>Guests may book an Experience with a Host through the Platform.</p>
                    </li>
                    <li data-list-text="4.2.">
                        <p  className="extraspace" style={{paddingTop: "2pt",paddingLeft: "3pt",textAlign: "justify"}}>Guests agree to pay the full amount of the booking including service fee at the time of booking through the Platform and will be held in escrow until the experience has been completed.</p>
                    </li>
                    <li data-list-text="4.3.">
                        <p className="extraspace" style={{paddingLeft: "2pt",textAlign: "justify"}}>The service fee collected by Wareeg is included in the total amount at the time of booking.</p>
                    </li>
                    <li data-list-text="4.4.">
                        <p className="extraspace" style={{paddingLeft: "2pt",textAlign: "justify"}}>Hosts receive payment for their Experience through the Platform, minus our service fee.</p>
                    </li>
                    <li data-list-text="4.5.">
                        <p className="extraspace" style={{paddingLeft: "2pt",textAlign: "justify"}}>Hosts agree to provide the Experience as described in their profile and to communicate with the Guest to plan the details of the Experience.</p>
                    </li>
                </ol>
            </li>
            <li data-list-text="5.">
                <h4 className="extraheading2" style={{paddingLeft: "20pt",textIndent: "-18pt",textAlign: "left",color:"#4189DD",fontWeight:"600",fontSize:"18px"}}>Communication</h4>
                <ol id="tl6">
                    <li data-list-text="5.1.">
                        <p className="extraspace"  style={{paddingLeft: "1pt",textAlign: "justify"}}>All communication between Experiencers and Locals must occur through the Platform.</p>
                    </li>
                    <li data-list-text="5.2.">
                        <p className="extraspace" style={{paddingLeft: "1pt",textAlign: "justify"}}>Users may communicate with each other through the Platform to plan the details of the Experience.</p>
                    </li>
                    <li data-list-text="5.3.">
                        <p  className="extraspace"style={{paddingLeft: "1pt",textAlign: "justify"}}>Users are encouraged to use the Platform to document their communication and to report any issues or complaints.</p>
                    </li>
                </ol>
            </li>
            <li data-list-text="6.">
                <h4 className="extraheading2" style={{paddingLeft: "20pt",textIndent: "-18pt",textAlign: "left",color:"#4189DD",fontWeight:"600",fontSize:"18px"}}>Reviews and Ratings</h4>
                <ol id="tl7">
                    <li data-list-text="6.1.">
                        <p className="extraspace" style={{paddingLeft: "1pt",textAlign: "justify"}}>Guests may leave a review and rating of their Experience with a Host.</p>
                    </li>
                    <li data-list-text="6.2.">
                        <p className="extraspace" style={{paddingLeft: "1pt",textAlign: "justify"}}>Hosts may leave a review and rating of their interaction with a Guest.</p>
                    </li>
                    <li data-list-text="6.3.">
                        <p  className="extraspace" style={{paddingLeft: "1pt",textAlign: "justify"}}>Reviews and ratings must be honest and based on actual experiences.</p>
                    </li>
                    <li data-list-text="6.4.">
                        <p className="extraspace" style={{paddingLeft: "1pt",textAlign: "justify"}}>We reserve the right to remove reviews and ratings that violate these Terms.</p>
                    </li>
                </ol>
            </li>
            <li data-list-text="7.">
                <h4 className="extraheading2"  style={{paddingLeft: "20pt",textIndent: "-18pt",lineHeight: "16pt",textAlign: "left",color:"#4189DD",fontWeight:"600",fontSize:"18px"}}>Cancellation and Refunds</h4>
                <ol id="tl8">
                    <li data-list-text="7.1.">
                        <p className="extraspace" style={{paddingLeft: "1pt",textAlign: "justify"}}>Guests may cancel their booking up to 24 hours before the scheduled start time for a full refund minus any applicable fees.</p>
                    </li>
                    <li data-list-text="7.2.">
                        <p className="extraspace" style={{paddingLeft: "1pt",textAlign: "justify"}}>Hosts may cancel a booking at any time, if host decides to do so, they will forfeit any payout associated with that booking.</p>
                    </li>
                    <li data-list-text="7.3.">
                        <p className="extraspace" style={{paddingLeft: "1pt",textAlign: "justify"}}>If a Host fails to show up for a scheduled Experience, they will not be eligible for any payout.</p>
                    </li>
                    <li data-list-text="7.4.">
                        <p className="extraspace" style={{paddingLeft: "1pt",textAlign: "justify"}}>If a Guest fails to show up for a scheduled Experience, they will not be entitled to a refund.</p>
                    </li>
                </ol>
            </li>
            <li data-list-text="8.">
                <h4 className="extraheading2" style={{paddingLeft: "20pt",textIndent: "-18pt",textAlign: "left",color:"#4189DD",fontWeight:"600",fontSize:"18px"}}>Hosting on Wareeg</h4>
                <ol id="tl9">
                    <li data-list-text="8.1.">
                        <p className="extraspace" style={{paddingLeft: "1pt",textAlign: "justify"}}>Hosts may not ask Guests to pay them or make arrangements on their behalf outside of the Platform.</p>
                    </li>
                    <li data-list-text="8.2.">
                        <p className="extraspace" style={{paddingTop: "1pt",paddingLeft: "1pt",textAlign: "justify"}}>For Experiences involving prior booking, Hosts should direct Guests with a link to the venue or activity and have them pay on their own. Otherwise, Guests should pay at the location at the time of the Experience.</p>
                    </li>
                    <li data-list-text="8.3.">
                        <p className="extraspace" style={{paddingLeft: "1pt",textAlign: "justify"}}>Hosts are independent contractors and nothing is withheld from their pay.</p>
                    </li>
                </ol>
            </li>
            <li data-list-text="9.">
                <h4 className="extraheading2" style={{paddingLeft: "20pt",textIndent: "-18pt",textAlign: "left",color:"#4189DD",fontWeight:"600",fontSize:"18px"}}>Experience Details</h4>
                <ol id="tl10">
                    <li data-list-text="9.1.">
                        <p className="extraspace" style={{paddingLeft: "1pt",textAlign: "justify"}}>Hosts are responsible for providing accurate and complete information about their Experience, including the date, time, location, and description.</p>
                    </li>
                    <li data-list-text="9.2.">
                        <p className="extraspace" style={{paddingLeft: "1pt",textAlign: "justify"}}>Guests are responsible for reviewing and confirming the details of the Experience before booking.</p>
                    </li>
                </ol>
            </li>
            <li data-list-text="10.">
                <h4 className="extraheading2" style={{paddingLeft: "20pt",textIndent: "-18pt",textAlign: "left",color:"#4189DD",fontWeight:"600",fontSize:"18px"}}>Issues and Complaints</h4>
                <ol id="tl11">
                    <li data-list-text="10.1.">
                        <p className="extraspace" style={{paddingLeft: "1pt",textAlign: "justify"}}>Users are encouraged to communicate directly with each other to resolve any issues or complaints that may arise during or after an Experience.</p>
                    </li>
                    <li data-list-text="10.2.">
                        <p className="extraspace" style={{paddingLeft: "1pt",textAlign: "justify"}}>If a User is unable to resolve an issue or complaint with the other party, they may contact Wareeg for assistance.</p>
                    </li>
                </ol>
            </li>
            <li data-list-text="11.">
                <h4  className="extraheading2" style={{paddingLeft: "20pt",textIndent: "-18pt",textAlign: "left",color:"#4189DD",fontWeight:"600",fontSize:"18px"}}>Prohibited Conduct</h4>
                <ol id="tl12">
                    <li data-list-text="11.1.">
                        <p className="extraspace" style={{paddingLeft: "20pt",textIndent: "-18pt",textAlign: "left"}}>Users may not use the Platform to engage in any prohibited conduct, including but not limited to:</p>
                        <ol id="tl13">
                            <li data-list-text="11.1.1.">
                                <p className="extraspace" style={{paddingLeft: "16pt",lineHeight: "16pt",textAlign: "justify"}}>Harassment or discrimination of any kind</p>
                            </li>
                            <li data-list-text="11.1.2.">
                                <p className="extraspace" style={{paddingLeft: "16pt",textAlign: "justify"}}>Fraudulent or unlawful activity</p>
                            </li>
                            <li data-list-text="11.1.3.">
                                <p className="extraspace"  style={{paddingLeft: "16pt",textAlign: "justify"}}>Posting false or misleading information</p>
                            </li>
                            <li data-list-text="11.1.4.">
                                <p className="extraspace" style={{paddingLeft: "16pt",textAlign: "justify"}}>Violating the intellectual property rights of others</p>
                            </li>
                        </ol>
                    </li>
                </ol>
            </li>
            <li data-list-text="12.">
                <h4 className="extraheading2"  style={{paddingTop: "1pt",paddingLeft: "20pt",textIndent: "-18pt",textAlign: "left",color:"#4189DD",fontWeight:"600",fontSize:"18px"}}>Reporting Violations</h4>
                <ol id="tl14">
                    <li data-list-text="12.1.">
                        <p className="extraspace" style={{paddingLeft: "1pt",textAlign: "justify"}}>Users are encouraged to report any violations of these Terms or any unlawful activity to Wareeg.</p>
                    </li>
                    <li data-list-text="12.2.">
                        <p className="extraspace" style={{paddingLeft: "1pt",textAlign: "justify"}}>We will investigate all reports of violations and take appropriate action.</p>
                    </li>
                </ol>
            </li>
            <li data-list-text="13.">
                <h4  className="extraheading2" style={{paddingLeft: "20pt",textIndent: "-18pt",textAlign:"left",color:"#4189DD",fontWeight:"600",fontSize:"18px"}}>Indemnity</h4>
                <ol id="tl15">
                    <li data-list-text="13.1.">
                        <p className="extraspace" style={{paddingLeft: "1pt",textAlign: "left"}}>Users agree to indemnify and hold Wareeg, its affiliates, officers, agents, employees, and partners harmless from and against any and all claims, liabilities, damages, losses, and expenses, including reasonable attorneys&#39;fees and costs, arising out of or in any way connected with:</p>
                        <ol id="tl16">
                            <li data-list-text="13.1.1.">
                                <p className="extraspace" style={{paddingLeft: "16pt",lineHeight: "16pt",textAlign: "justify"}}>Users’ use of the Platform;</p>
                            </li>
                            <li data-list-text="13.1.2.">
                                <p className="extraspace" style={{paddingLeft: "16pt",textAlign: "justify"}}>Users’ violation of these Terms;</p>
                            </li>
                            <li data-list-text="13.1.3.">
                                <p className="extraspace" style={{paddingLeft: "16pt",textAlign: "justify"}}>Users’ violation of any rights of another party;</p>
                            </li>
                            <li data-list-text="13.1.4.">
                                <p className="extraspace" style={{paddingLeft: "16pt",textAlign: "justify"}}>Users’ violation of any law or regulation;</p>
                            </li>
                            <li data-list-text="13.1.5.">
                                <p className="extraspace" style={{paddingLeft: "15pt",textAlign: "justify"}}>Users’ interaction with any other User or third party, including but not limited to Experiencers (Guests) and Locals (Hosts);</p>
                            </li>
                            <li data-list-text="13.1.6.">
                                <p className="extraspace" style={{paddingLeft: "15pt",textAlign: "justify"}}>Any content or information posted or transmitted by Users on or through the Platform;</p>
                            </li>
                            <li data-list-text="13.1.7.">
                                <p className="extraspace" style={{paddingLeft: "15pt",textAlign: "justify"}}>Any dispute or issue that arises between Users or with a third party, including but not limited to any personal injury, death, or property damages that occurs during an Experience;</p>
                            </li>
                            <li data-list-text="13.1.8.">
                                <p className="extraspace" style={{paddingLeft: "15pt",textAlign: "justify"}}>Any claims made by a third party that the content or information posted or transmitted by Users on or through the Platform infringes on their intellectual property rights;</p>
                            </li>
                            <li data-list-text="13.1.9.">
                                <p className="extraspace" style={{paddingLeft: "15pt",textAlign: "justify"}}>Any claim that Wareeg is responsible for any taxes, duties, or fees related to any transaction or payment made through the Platform; or</p>
                            </li>
                            <li data-list-text="13.1.10.">
                                <p className="extraspace" style={{paddingLeft: "15pt",textAlign: "justify"}}>Any claim that Wareeg has failed to comply with any applicable law or regulation.</p>
                            </li>
                        </ol>
                    </li>
                    <li data-list-text="13.2.">
                        <p className="extraspace" style={{paddingLeft: "1pt",textAlign: "left"}}>This indemnification provision shall survive the termination of these Terms and Users&#39;use of the Platform. Users agree to cooperate fully with Wareeg in the defense of any such claim. Wareeg reserves the right, at its own expense, to assume the exclusive defense and control of any matter subject to indemnification by Users.</p>
                    </li>
                </ol>
            </li>
            <li data-list-text="14.">
                <h4 className="extraheading2"  style={{paddingLeft:"20pt",textIndent: "-18pt",lineHeight:"16pt",textAlign: "left",color:"#4189DD",fontWeight:"600",fontSize:"18px"}}>Limitation of Liability</h4>
                <ol id="tl17">
                    <li data-list-text="14.1.">
                        <p className="extraspace" style={{paddingLeft: "1pt",textAlign: "justify"}}>By using the Wareeg platform, Users acknowledge and agree that Wareeg is not liable for any direct, indirect, incidental, special, consequential, or punitive damages arising out of or relating to these Terms or the use of the Platform. This includes, but is not limited to, any damages arising from personal injury, property damage, lost profits, loss of data, or business interruption.</p>
                    </li>
                    <li data-list-text="14.2.">
                        <p  className="extraspace" style={{paddingLeft: "1pt",textAlign: "justify"}}>Users agree that they assume all risk associated with using the Platform and booking or participating in an Experience. Wareeg is not responsible for the quality, safety, or legality of any Experience offered by Hosts. Users acknowledge and agree that any bookings and participation in Experiences are done at their own risk.</p>
                    </li>
                    <li data-list-text="14.3.">
                        <p className="extraspace" style={{paddingLeft: "1pt",textAlign: "justify"}}>In no event shall Wareeg be liable for any damages, whether in contract, tort, strict liability, or otherwise, arising from or in connection with the use of the Platform or any Experience, even if Wareeg has been advised of the possibility of such damages.</p>
                    </li>
                    <li data-list-text="14.4.">
                        <p className="extraspace" style={{paddingLeft: "1pt",textAlign: "justify"}}>Users agree to indemnify, defend, and hold Wareeg and its affiliates, officers, agents, and employees harmless from any claims,damages, or losses arising from their use of the Platform or participation in any Experience.</p>
                    </li>
                </ol>
            </li>
            <li data-list-text="15.">
                <h4 className="extraheading2" style={{paddingLeft: "20pt",textIndent: "-18pt",textAlign: "left",color:"#4189DD",fontWeight:"600",fontSize:"18px"}}>Termination</h4>
                <ol id="tl18">
                    <li data-list-text="15.1.">
                        <p className="extraspace" style={{paddingLeft: "1pt",textAlign: "justify"}}>We may terminate a User&#39;s account at any time for any reason, including but not limited to, a violation of these Terms and Conditions or a violation of applicable laws.</p>
                    </li>
                    <li data-list-text="15.2.">
                        <p className="extraspace" style={{paddingLeft: "1pt",textAlign: "justify"}}>In the event that a Local&#39;s account is terminated, any existing bookings will be cancelled and any payments will be refunded to the Experiencer per our cancellation policy.</p>
                    </li>
                    <li data-list-text="15.3.">
                        <p className="extraspace" style={{paddingLeft: "1pt",textAlign: "justify"}}>Hosts acknowledge that they may be rejected from hosting an experience at any time, regardless of whether a booking has already been made, and acknowledge that this may disrupt any existing bookings. Wareeg will not guarantee the availability of a replacement host or the continuity of the experience.</p>
                    </li>
                    <li data-list-text="15.4.">
                        <p  className="extraspace" style={{paddingLeft: "1pt",lineHeight: "16pt",textAlign: "justify"}}>Upon termination, Users will no longer have access to the Platform.</p>
                    </li>
                </ol>
            </li>
            <li data-list-text="16.">
                <h4 className="extraheading2" style={{paddingLeft: "20pt",textIndent: "-18pt",textAlign: "left",color:"#4189DD",fontWeight:"600",fontSize:"18px"}}>Legal Mandates</h4>
                <ol id="tl19">
                    <li data-list-text="16.1.">
                        <p className="extraspace" style={{paddingLeft: "1pt",textAlign: "justify"}}>We may be required to cooperate with law enforcement or other jurisdictions in investigating unlawful activity or violations of these Terms.</p>
                    </li>
                    <li data-list-text="16.2.">
                        <p className="extraspace" style={{paddingLeft: "1pt",textAlign: "justify"}}>We reserve the right to disclose any information necessary to comply with legal mandates or to protect the safety and security of our Users.</p>
                    </li>
                </ol>
            </li>
            <li data-list-text="17.">
                <h4 className="extraheading2"style={{paddingLeft: "20pt",textIndent: "-18pt",textAlign: "left",color:"#4189DD",fontWeight:"600",fontSize:"18px"}}>Copyright Infringement</h4>
                <ol id="tl20">
                    <li data-list-text="17.1.">
                        <p className="extraspace" style={{paddingLeft: "1pt",textAlign: "justify"}}>We respect the intellectual property rights of others and expect our Users to do the same.</p>
                    </li>
                    <li data-list-text="17.2.">
                        <p className="extraspace" style={{paddingLeft: "1pt",textAlign: "justify"}}>We will investigate all reports of copyright infringement and take appropriate action.</p>
                    </li>
                </ol>
            </li>
            <li data-list-text="18.">
                <h4 className="extraheading2" style={{paddingLeft: "20pt",textIndent: "-18pt",lineHeight: "16pt",textAlign:"left",color:"#4189DD",fontWeight:"600",fontSize:"18px"}}>Intellectual Property</h4>
                <ol id="tl21">
                    <li data-list-text="18.1.">
                        <p className="extraspace" style={{paddingLeft: "3pt",textAlign: "justify"}}>Users retain ownership of their own intellectual property rights.</p>
                    </li>
                    <li data-list-text="18.2.">
                        <p className="extraspace" style={{paddingLeft: "1pt",textAlign: "justify"}}>Users grant us a non-exclusive, royalty-free, transferable, sub-licensable, worldwide license to use any content they upload to the Platform.</p>
                    </li>
                </ol>
            </li>
            <li data-list-text="19.">
                <h4 className="extraheading2" style={{paddingLeft: "20pt",textIndent: "-18pt",textAlign: "left",color:"#4189DD",fontWeight:"600",fontSize:"18px"}}>Arbitration and Class Action Waiver</h4>
                <ol id="tl22">
                    <li data-list-text="19.1.">
                        <p  className="extraspace" style={{paddingLeft: "1pt",textAlign: "justify"}}>Any disputes arising out of or relating to these Terms will be resolved through binding arbitration.</p>
                    </li>
                    <li data-list-text="19.2.">
                        <p className="extraspace" style={{paddingLeft: "1pt",textAlign: "justify"}}>Users waive the right to participate in any class action lawsuit or class-wide arbitration.</p>
                    </li>
                </ol>
            </li>
            <li data-list-text="20.">
                <h4 className="extraheading2" style={{paddingLeft: "20pt",textIndent: "-18pt",textAlign: "left",color:"#4189DD",fontWeight:"600",fontSize:"18px"}}>Modification</h4>
                <ol id="tl23">
                    <li data-list-text="20.1.">
                        <p className="extraspace" style={{paddingLeft: "1pt",textAlign: "justify"}}>We reserve the right to modify these Terms at any time.</p>
                    </li>
                    <li data-list-text="20.2.">
                        <p className="extraspace" style={{paddingTop: "1pt",paddingLeft: "1pt",textAlign: "justify"}}>Users will be notified of any changes to these Terms via the Platform or by email.</p>
                    </li>
                </ol>
            </li>
            <li data-list-text="21.">
                <h4 className="extraheading2" style={{paddingLeft: "3pt",textAlign:"left",color:"#4189DD",fontWeight:"600",fontSize:"18px"}}>Force Majeure</h4>
                <ol id="tl24">
                    <li data-list-text="21.1.">
                        <p  className="extraspace" style={{paddingLeft: "2pt",textAlign: "justify"}}>If either party is prevented from performing its obligations under these Terms and Conditions due to circumstances beyond its reasonable control, such as acts of God, natural disasters, war, terrorism, riots, embargoes, acts of civil or military authorities, fire, floods, accidents, pandemics, epidemics or disease, strikes, shortages of transportation facilities, fuel, energy, labor, or materials, Wareeg shall not be liable for any resulting delay or failure. In such a situation, the affected party will promptly inform the other party and use reasonable commercial efforts to resume performance as soon as it is reasonably practicable.</p>
                    </li>
                </ol>
            </li>
            <li data-list-text="22.">
                <h4 className="extraheading2" style={{paddingLeft: "20pt",textIndent: "-18pt",textAlign: "left",color:"#4189DD",fontWeight:"600",fontSize:"18px"}}>General Provisions</h4>
                <ol id="tl25">
                    <li data-list-text="22.1.">
                        <p className="extraspace" style={{paddingLeft: "1pt",textAlign: "justify"}}>These Terms constitute the entire agreement between Users and Wareeg.</p>
                    </li>
                    <li data-list-text="22.2.">
                        <p className="extraspace" style={{paddingLeft: "1pt",textAlign: "justify"}}>These Terms shall be governed by and construed in accordance with the laws of the State of Delaware where Wareeg Corporation is based.</p>
                    </li>
                    <li data-list-text="22.3.">
                        <p className="extraspace" style={{paddingLeft: "1pt",textAlign: "justify"}}>Users agree to resolve any disputes arising out of or relating to these Terms.</p>
                    </li>
                </ol>
            </li>
            <li data-list-text="23.">
                <h4 className="extraheading2" style={{paddingLeft: "20pt",textIndent: "-18pt",lineHeight: "16pt",textAlign: "left",color:"#4189DD",fontWeight:"600",fontSize:"18px"}}>Miscellaneous</h4>
                <ol id="tl26">
                    <li data-list-text="23.1.">
                        <p className="extraspace" style={{paddingLeft: "1pt",textAlign: "justify"}}>These Terms do not create any agency, partnership, or joint venture relationship between Users and Wareeg.</p>
                    </li>
                    <li data-list-text="23.2.">
                        <p className="extraspace" style={{paddingLeft: "1pt",textAlign: "justify"}}>If any provision of these Terms is found to be invalid or unenforceable, the remaining provisions will remain in effect.</p>
                    </li>
                    <li data-list-text="23.3.">
                        <p className="extraspace" style={{paddingLeft:  "1pt",textAlign: "justify"}}>These Terms are binding.</p>
                    </li>
                </ol>
            </li>
        </ol>
        </div>
        </>
    )

}
export default TermsAndConPol;