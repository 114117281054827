import React from "react";
import  "../Experiencer/Experiencer.css";
import { useNavigate } from "react-router-dom";
import host from "../../../assets/sliderDiv/local/host.svg";
import collaborate from "../../../assets/sliderDiv/local/collaborate.svg";
import experience from "../../../assets/sliderDiv/local/experience-1.svg";
import { Link } from "react-router-dom";
import './local.css'
// import { LazyLoadImage } from "react-lazy-load-image-component";
const Local = () => {
  const navigate=useNavigate();
  return (
    <div className='container-fluid experiencerContainer'>
      <div className='row eachContent'>
      <div className='col-1'>
          <img className='browse' src={host} alt="browse_button" />
        </div>
        <div className='col-11 para'>
          <p className='paraHeading'>Host When You Want</p>
          <p className='paraContent'>
          Accept booking requests that align with your schedule and earn income through shared interests.
          </p>
        </div>
      </div>
      <div className='row eachContent'>
      <div className='col-1'>
          <img className='browse'  src={collaborate} alt="book_button" />
        </div>
        <div className='col-11 para'>
          <p className='paraHeading'>Collaborate</p>
          <p className='paraContent'>
          Partner with experiencers to create personalized, exciting experiences.
          </p>
        </div>
      </div>
      <div className='row eachContent'>
      <div className='col-1'>
          <img
            className='browse' 
            src={experience}
            alt="experience_button"
          />
        </div>
        <div className='col-11 para'>
          <p className='paraHeading'>Experience</p>
          <p className='paraContent'>
          Offer unique experiences, anytime and anywhere, from sunrise hikes to nighttime adventures and everything in between.
          </p>
        </div>
        <Link   to="https://helpcenter.wareeg.io/how-do-i-apply-to-become-a-wareeg-local/" style={{color:"#fff",style:"none",textDecoration:"none"}}>   <div className="becomelocalbtn" style={{marginTop:'20px'}}>Become a local</div></Link>
      </div>

    </div>
  );
};
export default Local;
