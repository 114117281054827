import './cancellationpol.css'
const CancellationPol=()=>{
    return(
        <>
        <div className="cancelpol" style={{color:"#6B6B6B"}} >

        {/* <h1 style={{paddingTop:"4px",paddingLeft: "5px",textIndent: "0pt",textAlign: "left",fontWeight:"500",fontSize:"23px",color:"#061A32"}}>Wareeg Cancellation Policy</h1> */}
        
        {/* <p style={{textIndent:" 0pt",textAlign: "left"}}>
            <br/>
        </p> */}
        <h4 style={{textIndent: "0pt",textAlign:"left",fontWeight:"500",color:"#374151",fontSize:"20px" }}>For Experiencers:</h4>
        {/* <p style={{text-indent: 0pt;text-align: left}}>
            <br/>
        </p> */}
        <p className="extraspace"  style={{paddingTop: "10pt",textIndent: "0pt",textAlign: "justify", color:"#6B6B6B"}}>At Wareeg, we understand that unexpected circumstances can arise, and sometimes cancellations are necessary. To ensure a fair and consistent policy for all parties, we have established the following guidelines:</p>
        <ul id="l1">
        <h4 style={{paddingLeft: "16pt",textIndent: "-18pt",textAlign: "left",color:"rgb(65, 137, 221)",fontSize:"18px",marginBottom:"15px",fontFamily:"Poppins"}} className="extraheading">Cancellation:</h4>
            {/* <li data-list-text=""> */}
                
                <ul id="cl2" className="cancelli" style={{paddingLeft:'0rem'}}>
                    <li data-list-text="">
                        <p style={{paddingLeft:"25pt",textIndent:"-18pt",textAlign: "justify",marginBottom:"15px"}} className="extraspace">
                       <span id="listspan"  className="extradot"  style={{ width:"5px",
                height:"5px",
                display:"flex",
                alignItems:"top",
                opacity:"0.8",
                display:"inline-block",
                borderRadius: "50%",
                backgroundColor:"#6b6b6b" ,
                marginTop:"-2px",
                marginRight: "5px",
                position:"relative",
                top:"-2px"}}  ></span >    <span style={{color: "#374151",fontWeight:"600"}} > Pending reservation:</span> If the local has not accepted or declined the experience requested by the experiencer, the payment charges will be refunded and the funds will be returned.
                        </p>
                    </li>
                    <li data-list-text="">
                        <p style={{paddingLeft: "25pt",textIndent: "-18pt",textAlign: "justify",marginBottom:"15px"}} className="extraspace">
                        <span className="listspan"   style={{ width:"5px",
                height:"5px",
                display:"flex",
                alignItems:"top",
                opacity:"0.8",
                display:"inline-block",
                borderRadius: "50%",
                backgroundColor:"#6b6b6b" ,
                marginTop:"-2px",
                marginRight: "5px",
                position:"relative",
                top:"-2px"
                }}></span >  <span style={{color: "#374151",fontWeight:"600"}} > Confirmed reservation or no-show: </span>If a confirmed reservation is cancelled 24 hours before the start time, the Experiencer will receive a refund minus fees. However, there will be no refund for cancellations within 24 hours or no-shows.
                        </p>
                    </li>
                    <li data-list-text="">
                        <p style={{paddingLeft: "25pt",textIndent:" -18pt",textAlign: "justify",marginBottom:"15px"}} className="extraspace">
                        <span className="listspan" style={{ width:"5px",
                height:"5px",
                display:"flex",
                alignItems:"top",
                opacity:"0.8",
                display:"inline-block",
                borderRadius: "50%",
                backgroundColor:"#6b6b6b" ,
                marginTop:"-2px",
                position:"relative",
                top:"-2px",
                marginRight: "5px"}}></span > <span style={{color: "#374151",fontWeight:"600"}}> Local cancels or no-show:</span> Experiencer gets full refund minus fees.
                        </p>
                    </li>
                </ul>
            {/* </li> */}
            {/* <li data-list-text=""> */}
                <h4 style={{paddingTop: "1pt",paddingLeft:"16pt" ,textIndent: "-18pt",textAlign: "left",color:"#4189DD",fontSize:"18px",margin:"15px 0",fontFamily:"Poppins"}} className="extraheading">Booking Requests:</h4>
                <ul id="cl3">
                    <li data-list-text="o">
                        <p style={{textAlign: "justify"}} className="extraspace">To ensure timely processing, the local should either accept or decline the request within 48 hours or for same day bookings, before experience start time, otherwise it will expire. If declined or expired, there will be no charge to the Experiencer and the local will not receive payouts.</p>
                    </li>
                </ul>
                <h4 style={{paddingTop: "5pt",textIndent:"0pt" ,textAlign: "left",fontWeight:"500",marginBottom:"20pt",fontSize:"20px",color:"#374151",}}>For Locals:</h4>
            {/* </li> */}
            {/* <li data-list-text=""> */}
                <h4 style={{paddingLeft: "16pt",textIndent: "-18pt",textAlign: "left",color:"#4189DD",fontSize:"18px",fontFamily:"Poppins"}} className="extraheading">Payout:</h4>
                <ul id="cl4">
                    <li data-list-text="">
                        <p style={{paddingTop: "1pt",paddingLeft: "25pt",textIndent: "-18pt",textAlign: "justify",color: "#374151"}} className="extraspace">
                        <span className="extradot" style={{ width:"5px",
                height:"5px",
                display:"flex",
                alignItems:"top",
                opacity:"0.8",
                display:"inline-block",
                borderRadius: "50%",
                backgroundColor:"#6b6b6b" ,
                position:"relative",
                top:"-2px",
                marginTop:"-2px",
                marginRight: "5px"}}></span> <span style={{color: "#374151",fontWeight:"600"}}> Pending booking: </span><span className="p  extraspace">Local didn&#39;t accept or decline yet, no payout for them.</span>
                        </p>
                    </li>
                    <li data-list-text="">
                        <p style={{paddingTop: "1pt",paddingLeft: "25pt",textIndent: "-18pt",textAlign: "justify",color: "#374151"}} className="extraspace">
                        <span className="extradot" style={{ width:"5px",
                height:"5px",
                display:"flex",
                alignItems:"top",
                opacity:"0.8",
                display:"inline-block",
                borderRadius: "50%",
                backgroundColor:"#6b6b6b" ,
                marginTop:"-2px",
                position:"relative",
                top:"-2px",
                marginRight: "5px"}}></span>   <span style={{color: "#374151",fontWeight:"600"}}>Confirmed booking or no-show:</span>  <span className="p extraspace">Experiencer gets full refund if cancelled 24 hours before start time, no refund for cancellations within 24 hours or no-shows. Local gets full payout if cancelled within 24 hours or no-show.</span>
                        </p>
                    </li>
                    <li data-list-text="">
                        <p style={{paddingLeft: "25pt",textIndent: "-18pt",textAlign: "left",color: "#374151"}} className="extraspace">
                        <span className="extradot" style={{ width:"5px",
                height:"5px",
                display:"flex",
                alignItems:"top",
                opacity:"0.8",
                display:"inline-block",
                borderRadius: "50%",
                backgroundColor:"#6b6b6b" ,
                position:"relative",
                top:"-2px",
                marginTop:"-2px",
                marginRight: "5px"}}></span> <span style={{color: "#374151",fontWeight:"600"}}> Local cancels or no-show: </span> <span className="p extraspace">Experiencer gets full refund, local gets no payout.</span>
                        </p>
                    </li>
                </ul>
            {/* </li> */}
            {/* <li data-list-text=""> */}
                <h4 style={{paddingLeft: "16pt",textIndent:"-18pt",lineHeight:"16pt",textAlign: "left",color:"#4189DD",fontSize:"18px",margin:"15px 0",fontFamily:"Poppins"}} className="extraheading">Booking Requests:</h4>
            {/* </li> */}
        </ul>
        {/* <ul id="l5"> */}
            {/* <li data-list-text="o"> */}
                <p style={{textIndent: "-18pt",textIndent: "0pt",textAlign: "justify"}} className="extraspace">  Local must accept or decline within 48 hours, else it expires. If declined or expired, Experiencer won&#39;t be charged and local won&#39;t receive payouts.</p>
            {/* </li> */}
        {/* </ul> */}
        <p style={{paddingTop:"4pt",textIndent: "0pt",textAlign: "justify"}} className="extraspace">If either party is prevented from performing its obligations under the Terms and Conditions due to circumstances beyond its reasonable control, such as acts of God, natural disasters, war, terrorism, riots, embargoes, acts of civil or military authorities, fire, floods, accidents, pandemics, epidemics or disease, strikes, shortages of transportation facilities, fuel, energy, labor, or materials, Wareeg shall not be liable for any resulting delay or failure. In such a situation, the affected party will promptly inform the other party and use reasonable commercial efforts to resume performance as soon as it is reasonably practicable.</p>
       
        <p style={{textIndent: "0pt",textAlign: "justify"}} className="extraspace">Wareeg reserves the right to make changes to this Cancellation Policy at any time and for any reason, with or without notice. By using Wareeg you agree to this cancellation policy and any changes made in the future.</p>
        
        </div>
        
        </>
    )

}
export default CancellationPol;