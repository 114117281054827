import React, { useState } from "react";
import "./Title.css";
import InputMail from "../../UI/InputMail/InputMail";
import headerPhone from "../../assets/title/headerPhone.png";
import { LazyLoadImage } from "react-lazy-load-image-component";
const Title = (props) => {
  if (props.isHeaderButtonClicked && window.screen.width > 1200) {
    window.scrollBy(0, 250);
  }
  const [subscriptionDetails, setSubscriptionDetails] =
    useState("Invisible Text");
  const [startTimer, setStartTimer] = useState(false);

  if (startTimer) {
    setTimeout(() => setStartTimer(false), 5000);
  }
  return (
    <div className="container-fluid titleContainer">
      <div className="row titleWareeg">
        <div className="col-sm-12 col-md-6 headingfirstpara">
          <div className="para1" style={{fontFamily:"Poppins"}} >
            <div  >Experience your world, <br/>
            your way - join the<br/> world's first
            Experience-Sharing platform!</div>
          </div>
          <p className="para2" style={{textAlign:"start"}}>Download the Wareeg app on both iOS & Android Today</p>
          <div className="allappbtns">
           <span  className="appbtns" style={{paddingRight:"30px"}}> <img src="/images/icons/appstorebtn.svg" ></img> </span>
          <span  className="appbtns">  <img src="/images/icons/googleplaybtn.svg"></img></span>
          </div>
          {/* <div className="inputDiv">
            <InputMail
              setSubscriptionDetail={(detail) => setSubscriptionDetails(detail)}
              setStartTimer={(val) => setStartTimer(val)}
              isHeaderButtonClicked={props.isHeaderButtonClicked}
            />
          </div> */}

          <div
            className={`subscriptionDetails ${startTimer && "subscriptionDetailsVisible"
              }`}
          >
            {subscriptionDetails}
          </div>
        </div>

        <div class="image col-md-6  d-md-flex justify-content-center">
          <LazyLoadImage
            className="phoneImage"
            src={headerPhone}
            alt="a phone"
          />
        </div>
      </div>
    </div>
  );
};

export default Title;
