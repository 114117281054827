import LandingPage from "./LandingPage";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";


function App() {
  return (
    
   <LandingPage />
     
    
    
  );
}

export default App;
