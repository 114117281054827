import './communityguide.css'
const CommunityGuide=()=>{
    return(
        <>
           {/* <h1 style={{paddingTop: "4pt",paddingLeft: "5pt",textIndent: "0pt",textAlign: "left"}}>Wareeg Community Guidelines</h1> */}
        <p style={{paddingTop: "10pt",paddingLeft: "5pt",textIndent: "0pt",textAlign: "justify"}} className="extraspace">At Wareeg, we aim to create a community of respectful, trustworthy, and enjoyable experiences. In order to ensure that all users have a positive experience, we ask that all users abide by the following guidelines:</p>
        <ol id="cgl1">
            <li data-list-text="1.">
                <p style={{paddingTop: "8pt",paddingLeft: "7pt",textAlign: "left",textContent:"justify",lineHeight:"3rem"}} className="extraspace"><span style={{color:"#4189DD",fontWeight:"600"}}>Respect Others: </span>Treat everyone you interact with on Wareeg with respect and kindness. Avoid any behavior that could be considered harassing, threatening, or discriminatory.</p>
            </li>
            <li data-list-text="2.">
                <p style={{paddingLeft: "5pt",textAlign: "justify",lineHeight:"3rem",textContent:"justify"}} className="extraspace"><span style={{color:"#4189DD",fontWeight:"600"}}>Honest</span><span style={{color:"#4189DD",fontWeight:"600"}}><span style={{color:"white"}}>-</span>Communication: </span>Be transparent and honest in all your communications with other users. If you need to cancel an experience, please do so as soon as possible and communicate clearly with the organizer.</p>
            </li>
            <li data-list-text="3.">
                <p className="extraspace" style={{paddingLeft: "5pt",textAlign: "justify",lineHeight:"3rem"}}><span style={{color:"#4189DD",fontWeight:"600"}} className="extraspace">Respectful and Appropriate Content:</span> Do not post any content that is abusive, harmful, sexual, illicit, illegal, or otherwise inappropriate. All content should be respectful and in line with our community standards.</p>
            </li>
            <li data-list-text="4.">
                <p className="extraspace" style={{paddingLeft: "5pt",lineHeight:"3rem",textAlign: "justify"}}><span style={{color:"#4189DD",fontWeight:"600"}} className="extraspace">Safety First: </span>Your safety and the safety of others is always the top priority. If you have any concerns about your safety during an experience, please reach out to Wareeg support.</p>
            </li>
            <li data-list-text="5.">
                <p className="extraspace" style={{paddingLeft: "5pt",lineHeight:"3rem",textAlign: "justify"}}><span style={{color:"#4189DD",fontWeight:"600"}} className="extraspace">Respect Property:</span> Do not damage or destroy any property while participating in an experience. Be mindful of your surroundings and leave the location of the experience in the same condition as when you arrived.</p>
            </li>
            <li data-list-text="6.">
                <p className="extraspace" style={{paddingLeft: "5pt",lineHeight:"3rem",textAlign: "justify"}}><span style={{color:"#4189DD",fontWeight:"600"}} className="extraspace">Solicitation:</span> Do not engage in any illegal activities or solicit the use of illegal substances, and do not offer to drive guests or hosts to or from an experience. Any kind of solicitation, whether before, during or after an experience, is not allowed on our platform.</p>
            </li>
            <li data-list-text="7.">
                <p className="extraspace" style={{paddingLeft: "5pt",lineHeight:"3rem",textAlign: "justify"}}><span style={{color:"#4189DD",fontWeight:"600"}} className="extraspace">No Off-Platform Communication:</span> Do not make any bookings, arrangements, or communications between experiencer or local outside of the Wareeg platform. This is to ensure safety and security for all users and maintain the integrity of the Wareeg platform.</p>
            </li>
            <li data-list-text="8.">
                <p className="extraspace" style={{paddingLeft: "5pt",lineHeight:"3rem",textAlign: "justify"}}><span style={{color:"#4189DD",fontWeight:"600"}} className="extraspace">Report Unacceptable Behavior: </span>If you encounter any behavior that violates these community guidelines or is otherwise unacceptable, please report it to Wareeg support.</p>
            </li>
            <li data-list-text="9.">
                <p className="extraspace" style={{paddingLeft: "5pt",lineHeight:"3rem",textAlign: "justify"}}><span style={{color:"#4189DD",fontWeight:"600"}} className="extraspace">Follow Laws and Regulations:</span> Ensure that your actions while participating in an experience are in compliance with all applicable laws and regulations.</p>
            </li>
            <li data-list-text="10.">
                <p className="extraspace" style={{paddingLeft: "5pt",lineHeight:"3rem",textAlign: "justify"}}><span style={{color:"#4189DD",fontWeight:"600"}} className="extraspace">Trust and Fairness:</span> Wareeg operates on a trust-based system, and we expect all users to act fairly and honor their commitments. If you believe that another user has acted in a manner that violates this principle, please report it to Wareeg support.</p>
            </li>
        </ol>
        <p style={{paddingTop: "7pt",paddingLeft: "5pt",textIndent: "0pt",textAlign: "justify"}} className="extraspace">Wareeg reserves the right to remove any user who violates these community guidelines, and to take other actions as necessary to maintain a safe and enjoyable experience for all users.</p>
        <p style={{paddingTop: "4pt",paddingLeft: "5pt",textIndent: "0pt",textAlign: "justify"}} className="extraspace">By using Wareeg, you agree to abide by these community guidelines and to act in accordance with the principles of trust, respect, and safety. Thank you for your cooperation!</p>
        </>
    )

}
export default CommunityGuide;