import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import './thankyou.css'
import Header from "../Components/Header/Header";
import Footer from "../Components/Footer/Footer";
const ThankYou=()=>{
    const navigate=useNavigate();
useEffect(()=>{
setTimeout(()=>{navigate("/")},3000)
},[])
return<>
<div className="thankyoucontent">
<div   className="thankyou" >
    <div className="thankimg">
    <img  style={{position:"relative",alignItems:"center",width:"100%",height:"100%"}}src="/images/icons/Animation.gif" alt="thankyou"/></div>
<h1 style={{fontStyle:"Poppins",textAlign:"center"}}>Thank You For Reaching Out</h1>
<p style={{fontStyle:"Poppins",textAlign:"center",color:"#868484",fontWeight:"400"}}> Please allow 5-10 business days for a response from Wareeg. We appreciate your patience.</p>
</div>
</div>




</>
}
export default ThankYou;