import {useState} from 'react'
import CancellationPol from '../Policies/CancellationPol'
import TermsAndConPol from '../Policies/TermsAndConPol'
import CommunityGuide from '../Policies/CommunityGuideLines'
import RefundPolicy from '../Policies/RefundPolicy'
import './legal.css'
const Legal=({children,policyname})=>{
    const [legalTerms,setLegalTerms]=useState(false)
    const handleOpenHandler=()=>{
       setLegalTerms(prev=>!prev)
    }
    const handleCloseHandler=()=>{
        setLegalTerms(false)
    }

return(
    <>
    <div >
    <div className="commonpara" style={{display:"flex",justifyContent:"space-between",marginTop:"20px",cursor:"pointer"}} onClick={handleOpenHandler} >
    {/* <div className={policyname==="Cancellation Policy"?"policynamecancel":"policyname"} >{policyname}</div> */}
    <div className={legalTerms?policyname==="Cancellation Policy"?"policynamecancel legalheaderpol":"policyname legalheader":policyname==="Cancellation Policy"?"policynamecancel legalheaderf":"policyname legalheaderf"}>{policyname}</div>
      {! legalTerms?<div className="crossIcon" style={{cursor:"pointer"}}> <img src="/images/icons/Vector.png" /></div>:<div className="crossIcon" style={{cursor:"pointer"}}> <img src="/images/icons/closeIcon.svg" /></div>}
   
</div>

        {legalTerms&&<div style={{color:"#000"}}>
           {children}
            </div>}
            {policyname!=='Trademark Guidelines'?<hr style={{color:"gray",margin:"2rem 0rem"}}/>:<div style={{marginBottom:"7%"}}></div>}
        
</div>

    </>
)
}
export default Legal;