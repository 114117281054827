import React, { useState ,useRef,useEffect} from "react";
import classes from "./ToggleSwitch.module.css";

const ToggleSwitch = (props) => {
  const toggleRef = useRef()
  const stylecontent=props.stylecontent;
  const height=props.height;
  const [isExperiencer, setIsExperiencer] = useState(props.isExperiencer);

  useEffect(()=>{
    !props.isExperiencer  && toggleRef.current.scrollIntoView({behavior:'smooth'})
  },[])

  return (
    <div ref={toggleRef} className={classes.container} style={{justifyContent:stylecontent,height:height}}>
      <div className={classes.toggleButton}>
        <button
          onClick={() => {
            props.setIsExperiencer(true);
            setIsExperiencer(true);
          }}
          className={`${classes.toggleButtons} ${
            isExperiencer && classes.toggleLeft
          }`}
        >
          Experiencer
        </button>
        <button
          onClick={() => {
            props.setIsExperiencer(false);
            setIsExperiencer(false);
          }}
          className={`${classes.toggleButtons} ${
            !isExperiencer && classes.toggleRight
          }`}
        >
          Local
        </button>
      </div>
    </div>
  );
};

export default ToggleSwitch;
