import React from "react";
import "./DropDown.css";
const DropDown = (props) => {
  return (
    <div className="dropdown">
      <ul>
        {props.list.map((item) => (
          <li
            onClick={(e) => {
              props.setValue(e.target.value);
            }}
            value={item}
          >
            {item}
          </li>
        ))}
      </ul>
    </div>
  );
};
export default DropDown;
