import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import classes from "./ImageSlider.module.css";
// import { LazyLoadImage } from "react-lazy-load-image-component";

import slider1 from "../../../assets/sliderDiv/imageSlider/experiencer/slider1.png";
import slider2 from "../../../assets/sliderDiv/imageSlider/experiencer/slider2.png";
import slider3 from "../../../assets/sliderDiv/imageSlider/experiencer/slider3.png";
import slider4 from "../../../assets/sliderDiv/imageSlider/experiencer/slider4.png";
import slider5 from "../../../assets/sliderDiv/imageSlider/experiencer/slider5.png";
import slider6 from "../../../assets/sliderDiv/imageSlider/experiencer/slider6.png";
import slider7 from "../../../assets/sliderDiv/imageSlider/experiencer/slider7.png";
import slider8 from "../../../assets/sliderDiv/imageSlider/experiencer/slider8.png";
import slider9 from "../../../assets/sliderDiv/imageSlider/local/slider9.png";
import slider10 from "../../../assets/sliderDiv/imageSlider/local/slider10.png";
import slider11 from "../../../assets/sliderDiv/imageSlider/local/slider11.png";
import slider12 from "../../../assets/sliderDiv/imageSlider/local/slider12.png";
import slider13 from "../../../assets/sliderDiv/imageSlider/local/slider13.png";
import slider14 from "../../../assets/sliderDiv/imageSlider/local/slider14.png";




const ImageSlider = (props) => {

  return (
    <div className={classes.container}>
      {props.isExperiencer && (
        <Carousel
          className={classes.carousel}
          infiniteLoop={true}
          autoPlay={true}
          showStatus={false}
          showArrows={false}
          showThumbs={false}
          showIndicators={false}
          interval={2500}
        >
          <div>
            <img src={slider1} alt="screen" />
          </div>
          <div>
            <img src={slider2} alt="screen" />
          </div>
          <div>
            <img src={slider3} alt="screen" />
          </div>
          <div>
            <img src={slider4} alt="screen" />
          </div>
          <div>
            <img src={slider5} alt="screen" />
          </div>
          <div>
            <img src={slider6} alt="screen" />
          </div>
          <div>
            <img src={slider7} alt="screen" />
          </div>
          <div>
            <img src={slider8} alt="screen" />
          </div>
        </Carousel>
      )}
      {!props.isExperiencer && (
        <Carousel
          className={classes.carousel}
          infiniteLoop={true}
          autoPlay={true}
          showStatus={false}
          showArrows={false}
          showThumbs={false}
          showIndicators={false}
          interval={2500}
        >
          <div>
            <img src={slider9} alt="screen" />
          </div>
          <div>
            <img src={slider10} alt="screen" />
          </div>
          <div>
            <img src={slider11} alt="screen" />
          </div>
          <div>
            <img src={slider12} alt="screen" />
          </div>
          <div>
            <img src={slider13} alt="screen" />
          </div>
          <div>
            <img src={slider14} alt="screen" />
          </div>
        </Carousel>
      )}
    </div>
  );
};

export default ImageSlider;
