import React, { useState } from "react";
import classes from "./InputSelector.module.css";
import DropDown from "../../UI/DropDown/DropDown";
const InputSelector = (props) => {
  let hoursList = [];
  for (let i = 1; i <= 24; i++) {
    hoursList.push(i);
  }
  let peopleList = [];
  for (let i = 1; i <= 20; i++) {
    peopleList.push(i);
  }
  const [showHoursDropDown, setShowHoursDropDown] = useState(false);
  const [showPeopleDropDown, setShowPeopleDropDown] = useState(false);
  const [hours, setHours] = useState(1);
  const [people, setPeople] = useState(1);
  return (
    <div className={classes.input} >
      
      I want a{" "}
      <div
        onClick={() => {setShowHoursDropDown((prev) => !prev);setShowPeopleDropDown(false)}}
        className={classes.select}
      >
        {hours}
        {showHoursDropDown && (
          <DropDown
            list={hoursList}
            setValue={(val) => {
              setHours(val);
              props.setHours(val);
            }}
          />
        )}
     </div>
      hour experience with a party size of
      <div
        onClick={() => {setShowPeopleDropDown((prev) => !prev);setShowHoursDropDown(false)}}
        className={classes.select}
        style={{ color: "#0ddfbf" }}
      >
        {people}
        {showPeopleDropDown && (
          <DropDown
            list={peopleList}
            setValue={(val) => {
              setPeople(val);
              props.setPeople(val);
            }}
          />
        )}
      </div>
      {"  "}.
    </div>
  );
};
export default InputSelector;
