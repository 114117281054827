import lines from "../../assets/wareeg/amountLines.svg";
import classes from "./AmountDisplay.module.css";
// import CountUp from 'react-countup'
import AnimatedNumber from "react-animated-number";

const AmountDisplay = (props) => {
  let wareegAmount = Number(props.wareegAmount);
  let otherAmount = Number(props.otherAmount);
  
  return (
    <div className={classes.amountDiv}>
      <div className={classes.lineAlignment}>
        <div className={classes.wareegAmount} > 
        <div className={classes.parastwo} style={{display:"flex",flexDirection:"column",marginRight:"25px"}}>
        <div className={classes.p1}>The cost to experience</div>
          <div className={classes.p2} style={{marginBottom:'10px'}}>with Wareeg</div>
          </div>
          <div className={classes.amountWareeg}>$<AnimatedNumber  value={wareegAmount} duration={3000} formatValue={n=> n.toFixed(2)}/></div>
          
        </div>
        <img src={lines} alt="line" />
      </div>
      <div className={classes.othersAmount} >

        <div className={classes.amountOthers} style={{marginRight:"25px"}}>$<AnimatedNumber  value={otherAmount} duration={5000} formatValue={n=> n.toFixed(2)}/></div>
        <div className={classes.parastwo} style={{display:"flex",flexDirection:"column"}}>
        <div className={classes.p3}> The cost to experience</div>
        <div className={classes.p4}>with else where</div>
        </div>
      </div>
    </div>
  );
};

export default AmountDisplay;
