import CommunityGuide from "../Components/Policies/CommunityGuideLines";
import Header from "../Components/Header/Header";
import Footer from "../Components/Footer/Footer";
const Community=()=>{
    return(
        <>
        <Header/>
        <div style={{padding:"3% 7%"}}>
        <div className="headingterms"style={{fontWeight:"bold",color:"#061A32",fontFamily:"Poppins",marginBottom:"20px"}} >Community GuideLines</div>
        <CommunityGuide/>
        </div>
        <Footer/>
        </>
    )
}
export default Community;