import React, { useState } from "react";
import classes from "./Footer.module.css";
import wareeg from "../../assets/wareeg/wareeg.svg";
import InputMail from "../../UI/InputMail/InputMail";
import twitter from "../../assets/footer/twitter.svg";
import instagram from "../../assets/footer/instagram.svg";
import linkedin from "../../assets/footer/linkedin.svg";
import { Link } from "react-router-dom";
import './Footer.module.css'
import { useNavigate } from "react-router-dom";
import {isIOS,isAndroid,isMobile} from 'react-device-detect';
import Modal from 'react-modal'
import { useEffect } from "react";

const Footer = (props) => {
  const [subscriptionDetails, setSubscriptionDetails] =
    useState("Invisible Text");
    const [deviceType,setDeviceType]=useState("default");
    useEffect(()=>{
      if(isAndroid)
      {
        setDeviceType("isAndroid")
      }
      else if(isIOS && isMobile)
      {
        setDeviceType("isIOS")
      }
    },[])
  const [startTimer, setStartTimer] = useState(false);
  const [modalIsOpen, setIsOpen] =useState(false)
  const navigate=useNavigate();
  const closeModal=()=>{
    setIsOpen(false)
  }
  if (startTimer) {
    setTimeout(() => setStartTimer(false), 5000);
  }
const handleClick=()=>{
  navigate("/legal")
  window.scrollTo({top: 0, left: 0, behavior: 'smooth'})
 // props.setCompName("legal");
}
const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    height:'392px',
    width:"560px",
    backgroundColor:"white",
    borderRadius:"30px",
    overflow:"hidden",
    padding:'20px'
  },
};
  return (
    <div className={classes.container}>
      <div className={classes.innerContainer} >
        <div className={classes.footerfirstrow} >
      <div className={classes.footerfirstcolm} style={{marginTop:"-2%"}} >
      <img
              className={classes.wareegImage}
              src={wareeg}
              alt="name_wareeg"
              // style={{marginBottom:"20px"}}
            />
                { deviceType==="isAndroid"?<button  style={{padding:"14px 26px",borderRadius:"14px",color:"#4189DD",backgroundColor:"#fff",fontWeight:"500",border:"none",outline:"none",marginBottom:"20px",fontFamily:"Poppins"}} className={classes.scanbtn}><Link style={{textDecoration:"none"}}  target="_blank" to="https://play.google.com/store/apps/">
                Get Wareeg<img className={classes.scannerimg}style={{marginLeft:"6px",width:"28px",height:"27px"}}src="/images/icons/maskgroup.svg" />
              </Link></button>:deviceType==="isIOS"?<button  style={{padding:"14px 26px",borderRadius:"14px",color:"#4189DD",backgroundColor:"#fff",fontWeight:"500",border:"none",outline:"none",marginBottom:"20px",fontFamily:"Poppins"}} className={classes.scanbtn}><Link style={{textDecoration:"none"}} target="_blank" to="http://appstore.com/">
                Get Wareeg<img className={classes.scannerimg} style={{marginLeft:"6px",width:"28px",height:"27px"}}src="/images/icons/maskgroup.svg" />
              </Link></button>: <button style={{padding:"14px 26px",borderRadius:"14px",color:"#4189DD",backgroundColor:"#fff",fontWeight:"500",border:"none",outline:"none",marginBottom:"20px",fontFamily:"Poppins"}} onClick={()=>{setIsOpen(true)}} className={classes.scanbtn}>Get Wareeg<img style={{marginLeft:"6px",width:"28px",height:"27px"}}src="/images/icons/maskgroup.svg" /></button>}

              {/* <button style={{padding:"14px 12px",borderRadius:"14px",color:"#4189DD",fontSize:"17px",backgroundColor:"#fff",fontWeight:"500",border:"none",outline:"none",marginBottom:"20px"}} onClick={()=>{setIsOpen(true)}} className={classes.footergetwareegbtn}>Get Wareeg<img style={{marginLeft:"6px",width:"28px",height:"27px"}}src="/images/icons/maskgroup.svg" /></button> */}
            
             <div  className={classes.footerBottomLeft}>
             <Link  target="_blank" to="https://twitter.com/wareeghq?s=21&t=ESfwnsaiTQH-rgUiT_CFWA" style={{marginRight:"9%"}}>
                <img className={classes.image} src={twitter} alt={twitter}   />
              </Link>
              <Link  target="_blank" to="https://instagram.com/wareeghq?igshid=YmMyMTA2M2Y=" style={{marginRight:"9%"}}>
                <img
                  className={classes.image}
                  src={instagram}
                  alt={instagram}
                />
              </Link>
              <Link  target="_blank" to="https://www.linkedin.com/company/wareeg/">
                <img className={classes.image} src={linkedin} alt={linkedin} />
              </Link>
             </div>

      </div>
      <div className={classes.footerfirstcol} style={{color:"#fff",marginBottom:"2%",marginTop:"2%"}}>
        <div style={{marginBottom:"20px",fontFamily:"Poppins",fontWeight:"600",fontSize:"21px",lineHeight:"28px"}} className={classes.support}>Support</div>
        <Link   to="https://helpcenter.wareeg.io/" style={{color:"#fff",style:"none",textDecoration:"none"}}> <div style={{fontWeight:"300",marginBottom:"15px",fontFamily:"Poppins",fontWeight:"400",lineHeight:"35px"}} className={classes.namespolicy}> Help Center</div></Link>
        <div style={{fontWeight:"300",marginBottom:"15px",cursor:"pointer",fontFamily:"Poppins",fontWeight:"400",lineHeight:"28px"}} className={classes.namespolicy} onClick={()=>{navigate("/communityguidelines"); window.scrollTo({top: 0, left: 0, behavior: 'smooth'})}}>Community Guidelines</div>
        <div style={{fontWeight:"300",cursor:"pointer",fontFamily:"Poppins",fontWeight:"400"}} className={classes.namespolicy} onClick={()=>{navigate("/cancellationpolicy"); window.scrollTo({top: 0, left: 0, behavior: 'smooth'})}} >Cancellation Policy</div>
      </div>
      <div className={classes.footerthirdcol} style={{color:"#fff"}}>
        <div className={classes.namespolicylc} style={{fontWeight:"300",marginBottom:"10px",cursor:"pointer",fontFamily:"Poppins",fontWeight:"400",lineHeight:"28px"}} onClick={handleClick}>legal</div>
        <div  className={classes.namespolicylc}  style={{fontWeight:"300",cursor:"pointer",fontFamily:"Poppins",fontWeight:"400"}} onClick={()=>{navigate("/contactus"); window.scrollTo({top: 0, left: 0, behavior: 'smooth'})}}>Contact Us</div>
      </div>
      </div>
      <hr className={classes.one} />
      <div className={classes.footersecondrow} style={{marginTop:"10px"}}>
      <div className={classes.footersecondfirstcol} style={{fontWeight:"400",fontFamily:"Poppins",marginLeft:"30px"}} >
          © 2023 Wareeg Corporation
          </div>
          <div className={classes.footersecondsecondcol} style={{color:"#fff",display:"flex",marginRight:"30px"}}>
        <div style={{fontFamily:"Poppins",marginRight:'80%',cursor:"pointer"}} onClick={()=>{navigate("/privacy"); window.scrollTo({top: 0, left: 0, behavior: 'smooth'})}}>Privacy</div>
        <div style={{fontFamily:"Poppins",cursor:"pointer"}} className={classes.foooterterms}onClick={()=>{navigate("/terms"); window.scrollTo({top: 0, left: 0, behavior: 'smooth'})}}>Terms</div>
          </div>
          </div>
         
      </div>
      <Modal
        isOpen={modalIsOpen}
        //onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="QR CODE"//<img src="/images/icons/closeIcon.svg"/>
      >
    <span><img onClick={closeModal} style={{width:"25px",height:"25px",marginLeft:"511px",cursor:"pointer"}}src="/images/icons/closeIcon.svg"/></span> 
    <div  style={{display:"flex",flexDirection:"row",justifyContent:"space-between"}}>
      <div>
       <div style={{width:"200px",height:"200px",display:"flex",justifyContent:"center",alignItems:"center"}}><img src="/images/icons/appleplaystore.png" style={{width:"100%",height:"100%"}}/></div>
       <p style={{fontSize:"20px",fontWeight:"700",color:"#000",textAlign:"center"}} >Scan In  IOS  </p>
       </div>
       <div>
       <div style={{width:"200px",height:"200px",display:"flex",justifyContent:"center",alignItems:"center"}}><img src="/images/icons/playstorefile.png" style={{width:"100%",height:"100%"}}/></div>
       <p style={{fontSize:"20px",fontWeight:"700",color:"#000",textAlign:"center"}} >Scan In  Andriod  </p>
       </div>
       {/* <div>
       <div style={{width:"385.3px",height:"319.3px",display:"flex",justifyContent:"center",alignItems:"center"}}><img src="/images/icons/scannerimgboth.png" style={{width:"100%",height:"100%"}}/></div>
       {/* <p  style={{fontSize:"20px",fontWeight:"700",color:"#000",textAlign:"center"}} >Scan In Andriod  </p> */}
       {/* </div> */} 
        </div>  <p style={{textAlign:"center",color:"#000",fontWeight:"bold",fontSize:"26px",padding:"0 24px"}}>Scan the QR Code and Get Wareeg App</p>
       {/* <div onClick={closeModal} style={{cursor:"pointer",fontSize:"20px",color:"#fff",marginTop:"10px",textAlign:"right"}} ><button style={{border:"none",outline:"none",backgroundColor:"transparent"}}>Close</button></div> */}
      </Modal>

    </div>
  );
};

export default Footer;
 {/* <div className={classes.footerTopLeft}>
            <img
              className={classes.wareegImage}
              src={wareeg}
              alt="name_wareeg"
            />
          </div>
          <div className={classes.footerTopRight}>
            <div>
              <InputMail
                footer={true}
                setSubscriptionDetail={(detail) =>
                  setSubscriptionDetails(detail)
                }
                setStartTimer={(val) => setStartTimer(val)}
              />
              <div
                className={`${classes.subscriptionDetails} ${
                  startTimer && classes.subscriptionDetailsVisible
                }`}
              >
                {subscriptionDetails}
              </div>
            </div>
          </div> */}
            {/* <div className={classes.footerTop}> </div>
        <hr className={classes.one} />
        <div className={classes.footerBottom}>
          <div className={classes.footerBottomLeft}>
            <div>
              <Link  target="_blank" to="https://twitter.com/wareeghq?s=21&t=ESfwnsaiTQH-rgUiT_CFWA">
                <img className={classes.image} src={twitter} alt={twitter} />
              </Link>
            </div>
            <div>
              <Link  target="_blank" to="https://instagram.com/wareeghq?igshid=YmMyMTA2M2Y=">
                <img
                  className={classes.image}
                  src={instagram}
                  alt={instagram}
                />
              </Link>
            </div>
            <div>
              <Link  target="_blank" to="https://www.linkedin.com/company/wareeg/">
                <img className={classes.image} src={linkedin} alt={linkedin} />
              </Link>
            </div>
          </div>

          <div className={classes.footerBottomRight}>
            Copyright © 2023 Wareeg Corporation
          </div>
        </div> */}