import Legal from "../Components/Legal"
import Header from '../Components/Header/Header'
import Footer from "../Components/Footer/Footer"
import CancellationPol from "../Components/Policies/CancellationPol"
import RefundPolicy from "../Components/Policies/RefundPolicy"
import TradeMarkPol from "../Components/Policies/TradeMarkPol"
import CopyrightPol from "../Components/Policies/CopyRightPolicy.jsx"
import CommunityGuide from "../Components/Policies/CommunityGuideLines"
import PrivacyPol from "../Components/Policies/PrivacyPol"
import TermsAndConPol from "../Components/Policies/TermsAndConPol"
import './legalpage.css'
const LegalPage=()=>{
    return(
       <>
   <Header/>
   <div style={{padding:"0 7%"}} className="legalpage">
    <h1 className="headingterms" style={{fontFamily:"Poppins",padding:"20px 0px",fontWeight:"bold"}} >Legal Terms</h1>
        <Legal policyname="Cancellation Policy" >
            <CancellationPol />
        </Legal>
        <Legal policyname="Copyright Policy">
            <TradeMarkPol/>
        </Legal>
        <Legal policyname="Community Guidelines">
        <CommunityGuide/>
        </Legal>
        <Legal policyname="Privacy Policy">
       < PrivacyPol/>
        </Legal>
        <Legal policyname="Refund Policy">
        <RefundPolicy/>
        </Legal>
        <Legal policyname="Terms and Conditions">
          <TermsAndConPol/>
        </Legal>
        <Legal policyname="Trademark Guidelines">
        <TradeMarkPol/>
        </Legal>
        </div>
        <Footer/>
        </>
    )
}
export default LegalPage;