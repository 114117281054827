import React, { useState } from "react";
import Header from "./Components/Header/Header";
import Title from "./Components/Title/Title";
import ToggleSwitch from "./Components/ToggleSwitch/ToggleSwitch";
import SliderDiv from "./Components/SliderDiv/SliderDiv";
import Calculator from "./Components/Calculator/Calculator";
import Footer from "./Components/Footer/Footer";
import { Routes, Route,useParams } from "react-router-dom";
import LegalPage from "./pages/LegalPage";
import ContactUs from "./pages/ContactUs";
import Community from "./pages/Community";
import Cancellation from "./pages/Cancellation";
import ThankYou from "./pages/Thankyou";
import Terms from "./pages/TermsPage";
import Privacy from "./pages/PrivacyPage";
//import ContactUsPage from "./pages/ContactUsPage";


const LandingPage = () => {
  const [isExperiencer, setIsExperiencer] = useState(true);
  const [isHeaderButtonClicked, setIsHeaderButtonClicked] = useState(false);
  const [compName,setCompName]=useState("mainpage")
  const {id} = useParams();
 
  //console.log(id)
  return (
    <Routes>
      <Route
        path="/"
        element={
          <>
            <Header
              setIsHeaderButtonClicked={(val) => setIsHeaderButtonClicked(val)}
            />
          { compName==="mainpage"&&<> <Title isHeaderButtonClicked={isHeaderButtonClicked} />
         
            <ToggleSwitch
              isExperiencer={true}
              setIsExperiencer={(value) => setIsExperiencer(value)}
              stylecontent="center"
              height="160px"
            />
            <SliderDiv isExperiencer={isExperiencer} />
            <Calculator /></>
            }

            {
              compName==="legal" && <LegalPage/>
            }
            <Footer isExperiencer={isExperiencer} setCompName={setCompName} />
          </>
        }
      />
      <Route
        path="/localWeb"
        element={
          <>
            <Header
              setIsHeaderButtonClicked={(val) => setIsHeaderButtonClicked(val)}
            />
            <Title isHeaderButtonClicked={isHeaderButtonClicked} />
            <ToggleSwitch
              setIsExperiencer={(value) => setIsExperiencer(!value)}
              isExperiencer={!isExperiencer}
              stylecontent="center"
              height="160px"
            />
            <SliderDiv
              isExperiencer={!isExperiencer}
              setIsExperiencer={(value) => setIsExperiencer(value)}
            />
            <Calculator />
            <Footer isExperiencer={isExperiencer} />
          </>
        }
      />
      <Route 
      path="/legal"
      element={<LegalPage/>}
      />
       <Route 
      path="/contactus"
      element={<ContactUs/>}
      />
       <Route 
      path="/communityguidelines"
      element={<Community/>}
      />
        <Route 
      path="/cancellationpolicy"
      element={<Cancellation/>}
      />
   
     <Route 
     path="/thankyou"
     element={<ThankYou/>}
     />
       <Route 
     path="/terms"
     element={<Terms/>}
     />
       <Route 
     path="/privacy"
     element={<Privacy/>}
     />
   </Routes>
    

  );
};

export default LandingPage;
