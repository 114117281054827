import React from "react";
import "./SliderDiv.css";

import Experiencer from "./Experiencer/Experiencer";
// import containerSlider from "../../assets/sliderDiv/imageSlider/containerSlider1.svg";
import Local from "./Local/Local";
import ImageSlider from "./Image Slider/ImageSlider";
import { LazyLoadImage } from "react-lazy-load-image-component";

const SliderDiv = (props) => {
  return (
    <div className='container-fluid '>
      {/* <div className={classes.sliderDiv}>
        <div className={classes.sliderDivLeft}>
          <div className={classes.ellipse}>
            <ImageSlider isExperiencer={props.isExperiencer} />
            <LazyLoadImage
              className={classes.containerSlider}
              src={containerSlider}
              alt="phone Frame"
            />
          </div>
        </div>
        <div className={classes.sliderDivRight}>
          
        </div>
      </div> */}
      <div className='row sliderContainer '>
        <div className='col-sm-12 col-md-6 d-flex justify-content-center align-items-center'>
        <div className='ellipse '>
            <ImageSlider isExperiencer={props.isExperiencer} />
            <LazyLoadImage
              className='containerSlider'
              src="/images/icons/containerSlider.svg"
              alt="phone Frame"
            />
          </div>
        </div>
        <div className='col-sm-12 col-md-6 d-flex align-items-center'>
        {props.isExperiencer ? <Experiencer /> : <Local />}
        </div>
      </div>
    </div>
  )
}

export default SliderDiv;
