import CommunityGuide from "../Components/Policies/CommunityGuideLines";
import Header from "../Components/Header/Header";
import Footer from "../Components/Footer/Footer";
import TermsAndConPol from "../Components/Policies/TermsAndConPol";
import './legalpage.css'
const TermsPage=()=>{
    return(
        <>
        <Header/>
        <div style={{padding:"3% 7%"}}>
        <div  className="headingterms" style={{fontWeight:"bold",color:"#061A32",fontFamily:"Poppins",marginBottom:"20px"}} >Terms and Conditions</div>
        <TermsAndConPol/>
        </div>
        <Footer/>
        </>
    )
}
export default TermsPage;