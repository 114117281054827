import Header from "../Components/Header/Header";
import Footer from "../Components/Footer/Footer";
import ToggleSwitch from "../Components/ToggleSwitch/ToggleSwitch";
import { isMobile } from 'react-device-detect';
//import { Button, ControlLabel, FormControl, FormGroup, HelpBlock } from 'react-bootstrap';
import { useState } from "react";
import axios from 'axios';
import { useEffect } from "react";
import { useRef } from "react";
import { useNavigate } from "react-router-dom";
import Alert from 'react-bootstrap/Alert'
import { Button } from "react-bootstrap";
import './legalpage.css';
import downarrow from '../assets/wareeg/downarrowbtn.png'
import Card from 'react-bootstrap/Card';



import './contactus.css'
const ContactUs = () => {
  const [isExperiencer, setIsExperiencer] = useState(true);
  const [localtype, setLocaltype] = useState("local")
  const [isNotEmpty, setIsNotEmpty] = useState(false)
  const [type, setType] = useState("date");
  const [alert, setAlert] = useState(false);
  const [labelfield, setLabelfield] = useState(false);
  const [dateclass, setDateclass] = useState("dateplaceholder")
  const navigate = useNavigate();
  const [selectOpen, setSelectOpen] = useState(false)
  // const [firstName,setFirstName]=useState('');
  // const [lastName,setLastName]=useState('');
  // const [email,setEmail]=useState('');
  // const [dob,setDob]=useState('');
  // const [bookingId,setBookingId]=useState('');
  // const [reason,setReason]=useState('');
  // const [zipCode,setZipCode]=useState('');
  // const [message,setMessage]=useState('');
  //const [count,setCount]=useState(0)
  const [details, setDetails] = useState({
    firstName: '',
    lastName: '',
    email: '',
    dob: '',
    bookingId: '',
    reason: '',
    zipCode: '',
    message: ''
  })
  const [error, setError] = useState("")



  const [errorMessages, setErrorMessages] = useState({
    firstNameErrMsg: "Only Alphabets Allowed",
    lastNameErrMsg: "Only Alphabets Allowed",
    emailErrMsg: "Enter Valid Email",
    dobErrMsg: "Enter Valid Dob",
    bookingIdErrMsg: "Enter Valid Booking Id",
    zipCodeErrMsg: "Enter Valid Zip Code",


  })
  // const findIsNotEmpty=()=>{

  // }
  const [errMsgValid, setErrMsgValid] = useState({
    isFirstName: false,
    isLastName: false,
    isEmail: false,
    isDob: false,
    isBookingId: false,
    isZipCode: false
  })
  const nameRef = useRef();
  const lastnameRef = useRef();
  const dobRef = useRef();
  const emailRef = useRef();
  const reasonRef = useRef();
  const messageRef = useRef();
  const zipcodeRef = useRef();
  const bookingidRef = useRef();


  const [disabled, setDisabled] = useState(true)
  const nameIsValid = details.firstName.trim() !== '';
  const lastnameIsValid = details.lastName.trim() !== '';
  const emailIsValid = details.email.trim() !== '';
  const dobIsValid = details.dob.trim() !== '';
  const bookingIdIsValid = details.bookingId.trim() !== '';
  const reasonIsValid = details.reason.trim() !== '';
  const zipCodeIsValid = details.zipCode.trim() !== '';
  const messageIsValid = details.message.trim() !== '';
  let validregex = /^([a-zA-Z0-9.-]+)@([a-zA-Z0-9-]+).([a-z]{2,8})(.[a-z]{1,8})$/;

  // const validemail=details.email.match(validregex);
  const validemail = validregex.test(details.email)
  let validCodeRegex = "/^[0-9]{5}$|^[A-Z][0-9][A-Z] ?[0-9][A-Z][0-9]$/";
  const regex = /^\d{5}$/;
  const validZipCode = regex.test(details.zipCode);
  const regex1 = /^[A-Za-z0-9]{9}$/;
  const validBookingId = regex1.test(details.bookingId)


  useEffect(() => {
    if (!validemail && emailIsValid) {
      setErrMsgValid({ ...errMsgValid, isEmail: true })
    }
    else {
      setErrMsgValid({ ...errMsgValid, isEmail: false })
    }
    if (!validBookingId && bookingIdIsValid  ) {
        setErrMsgValid({ ...errMsgValid, isBookingId: true })
       }
     else {
         setErrMsgValid({ ...errMsgValid, isBookingId: false })
       }
      //  if(details.reason==="Appeal Platform Suspension"||details.reason==="Appeal Platform Suspension"||details.reason==="Appeal Blocked Decision")
      //  {
      //   console.log("in resaon")
      //   if(details.firstName!==''&&details.lastName!==''&&details.email!==""&&details.dob!=""&&details.zipCode!==''&&details.reason!==''&&details.message!=='')
      //   {
      //     console.log("in set disabled")
      //    setDisabled(false)
      //   }
      //  }
    if (nameIsValid && lastnameIsValid && emailIsValid && dobIsValid && reasonIsValid && zipCodeIsValid && messageIsValid && validemail && validZipCode&&bookingIdIsValid&&validBookingId) {

      
      setDisabled(false)
    }
    else  if(details.reason==="Appeal Platform Suspension"||details.reason==="Appeal Platform Suspension"||details.reason==="Appeal Blocked Decision")
{
  if(details.firstName!==''&&details.lastName!==''&&details.email!==""&&details.dob!=""&&details.zipCode!==''&&details.reason!==''&&details.message!=='')
  {
    console.log("in set disabled")
   setDisabled(false)
  }
}
  
    else {
      setDisabled(true)
    }

  }, [nameIsValid, lastnameIsValid, emailIsValid, dobIsValid, reasonIsValid, zipCodeIsValid, messageIsValid, validemail, validZipCode, errMsgValid.isEmail,bookingIdIsValid,validBookingId,details.reason])
  useEffect(() => {
    if (isExperiencer) {
      setLocaltype("experiencer")
      setDetails({...details,reason:''});
      reasonRef.current.value = "";
    
    }
    else {
      setLocaltype("local")
      setDetails({...details,reason:''});
      reasonRef.current.value = "";
    }
  }, [isExperiencer])


  const handleInputChange = (e) => {
    const name = e.target.name;
    var value = e.target.value;
    if (name === "dob") {
      let val = value.split("-");
      let val1 = [val[1], val[2], val[0]]
      let val2 = val1.join("-")
      value = val2
      if (!isMobile) {
        setTimeout(() => {
          setType("text")
        }, 2000)
      }
    }
    const regexp = "^[1-5]\d*$"
    setDetails(values => ({ ...values, [name]: value }))
    if (name === 'zipCode') {
      const regex = /^\d{5}$/;
      const validCode = regex.test(value);
      if (!validCode) {
        setErrMsgValid({ ...errMsgValid, isZipCode: true })

      }
      else {
        setErrMsgValid({ ...errMsgValid, isZipCode: false })
      }

    }
    if (name === 'email') {
      let validregex = /^([a-zA-Z0-9.-]+)@([a-zA-Z0-9-]+).([a-z]{2,8})(.[a-z]{1,8})$/;

      const validemail = validregex.test(value);
      if (!validemail) {
        setErrMsgValid({ ...errMsgValid, isEmail: true })


      }
      else {
        setErrMsgValid({ ...errMsgValid, isEmail: false })
      }

    }
  }

  const findFunc = () => {
    if (details.firstName && details.lastName && details.bookingId && details.reason && details.zipCode && details.message && details.email && details.dob) {
      setIsNotEmpty(true);
    }
    else {
      setIsNotEmpty(false);
    }
    setDisabled(!(!errMsgValid.isFirstName && !errMsgValid.isLastName && !errMsgValid.isZipCode && !errMsgValid.isEmail && isNotEmpty))
  }
  const handleSubmit = (v) => {
    v.preventDefault();
    const detailsVal = details;
    const newValues = { ...detailsVal, usertype: localtype }
    saveDetails(newValues);
  }
  const saveDetails = (v) => {
   
    axios.post(`${process.env.REACT_APP_BASE_URL}/contact`, v, {
      headers: {
        'Authorization': 'Basic ' + btoa(process.env.REACT_APP_USERNAME + ':' + process.env.REACT_APP_PASSWORD),
      }

    }).then((res) => {
     
      if (res.data.status === 200) {
        navigate("/thankyou")
      }
      else if (res.data.status === 400) {
        setError(res.data.error)
        window.alert(res.data.error.errorCode)
      }
      else if (res.data.status === 401) {
        
      }
      // alert(res);

    }

    )
  }
  return (
    <>
      <Header />

      <div className="contactus">
        <h1 className="headingterms" style={{ padding: "30px 0px 0px 20px", fontWeight: "bold" }}>Contact Us</h1>
        <hr style={{ width: "195px", marginLeft: "28px", marginLeft: "27px" }} />
        <div style={{ justifyContent: "flex-start", marginLeft: '17px' }}>
          <ToggleSwitch
            isExperiencer={true}
            setIsExperiencer={(value) => setIsExperiencer(value)}
            stylecontent="left"
            height='80px'
           
          />
        </div>
        <form onSubmit={handleSubmit} className="contactform">
          <div className="input-row">
            <div className="input-group">
              <label className={details.firstName && disabled ? "contactusblue" : disabled ? "contactuslabel" : "contactusnotlabel"} style={{ fontWeight: "400", fontSize: "17px" }}>First Name</label>
              <input type="text" autoComplete="off" placeholder="Eg Mark" ref={nameRef} name="firstName" onChange={handleInputChange} className="contactinput" ></input>
              <span onClick={() => {
                setDetails({ ...details, firstName: "" })
                nameRef.current.value = "";
              }} style={{ position: "absolute", left: "95%", top: "30px", top: "30px", opacity: "0.7" }}>{details.firstName != "" && <img src="images/icons/closeIcon.svg" />}</span>
              {/* {errMsgValid.isFirstName&&<div style={{color:"red",fontSize:"17px"}}>Enter Valid Name</div> } */}

            </div>

            <div className="input-group2">
              <label className={details.lastName && disabled ? "contactusblue" : disabled ? "contactuslabel" : "contactusnotlabel"} style={{ fontWeight: "400", fontSize: "17px" }} >Last Name</label>
              <input type="text" autoComplete="off" placeholder="Eg Brown" name="lastName" ref={lastnameRef} onChange={handleInputChange} className="contactinput "></input>
              <span onClick={() => {
                setDetails({ ...details, lastName: "" })
                lastnameRef.current.value = "";
              }} style={{ position: "absolute", left: "95%", opacity: "0.7", top: "30px" }} className="crosslastname">{details.lastName != "" && <img src="images/icons/closeIcon.svg" />}</span>
              {/* {errMsgValid.isLastName&&<div style={{color:"red",fontSize:'17px'}}>Enter Valid Name</div> } */}
            </div>

          </div>
          <div className="input-row">


            <div className="input-group">
              <label className={errMsgValid.isEmail ? "contactusred" : details.email && disabled ? "contactusblue" : disabled ? "contactuslabel" : "contactusnotlabel"} style={{ fontWeight: "400", fontSize: "17px", marginTop: "10px" }}>Email Address</label>
              <input type="email" autoComplete="off" placeholder="markbrown@example.com" ref={emailRef} name="email" onChange={handleInputChange} className="contactinput" ></input>
              <span onClick={() => {
                setDetails({ ...details, email: "" })
                emailRef.current.value = "";
              }} style={{ position: "absolute", left: "95%", top: "30px", opacity: "0.7" }} >{details.email != "" && <img src="images/icons/closeIcon.svg" />}</span>
              {errMsgValid.isEmail && <div style={{ color: "red", fontSize: "17px" }}>Enter Valid Email</div>}

            </div>
            {!isMobile ?
              <div className="input-group2">
                <label className={details.dob && disabled ? "contactusblue" : disabled ? "contactuslabel" : "contactusnotlabel"} style={{ fontWeight: "400", fontSize: "17px" }}>Date of Birth</label>
                <input type={type} autoComplete="off" value={details?.dob} placeholder="DD/MM/YY" name="dob" id="myDateInput" ref={dobRef} onChange={handleInputChange} className={!details.dob ? "contactinput dateplaceholder " : "contactinput dateselectplaceholder"} style={{
                  fontSize: "17px", marginTop: "10px"
                }}></input>
                <span onClick={() => {
                  setDetails({ ...details, dob: "" })
                  dobRef.current.value = "";
                  setType("date")
                }} style={{ position: "absolute", left: "95%", opacity: "0.7", top: "30px" }} className="crosslastname">{details.dob != "" ? <img src="images/icons/closeIcon.svg" /> : isMobile ? <img style={{ marginTop: "15px", marginLeft: "-3px", zIndex: "999999", width: "18px", height: "18px" }} src="images/icons/calendericon.png" /> : ""}</span>
              </div> :
              <div className="input-group2">
                <label className={details.dob && disabled ? "contactusblue" : disabled ? "contactuslabel" : "contactusnotlabel"} style={{ fontWeight: "400", fontSize: "17px" }}>Date of Birth</label>
                <input type={type} autoComplete="off" value={details?.dob} onInput={() => { setType("text") }} placeholder="DD/MM/YY" name="dob" id="myDateInput" ref={dobRef} onChange={handleInputChange} className={!details.dob ? "contactinput dateplaceholder " : "contactinput dateselectplaceholder"} style={{
                  fontSize: "17px", marginTop: "10px"
                }}></input>
                <span onClick={() => {
                  setDetails({ ...details, dob: "" })
                  dobRef.current.value = "";
                  setType("date")
                }} style={{ position: "absolute", left: "95%", opacity: "0.7", top: "30px" }} className="crosslastname">{details.dob != "" ? <img src="images/icons/closeIcon.svg" /> : isMobile ? <img style={{ marginTop: "15px", marginLeft: "-3px", zIndex: "999999", width: "18px", height: "18px" }} src="images/icons/calendericon.png" /> : ""}</span>
              </div>
            }

          </div>
          <div className="input-row">
            <div className="input-group">
              {/* <label className={details.bookingId && disabled ? "contactusblue" : disabled ? "contactuslabel" : "contactusnotlabel"} style={{ fontWeight: "400", fontSize: "17px" }} >Experience Booking ID</label> */}

              <label className={errMsgValid.isBookingId ? "contactusred" : details.bookingId && disabled ? "contactusblue" : disabled ? "contactuslabel" : "contactusnotlabel"} style={{ fontWeight: "400", fontSize: "17px" }}>Experience Booking ID</label>
              <input type="text" autoComplete="off" placeholder="XXXXX56895" ref={bookingidRef} name="bookingId" maxLength={9} onChange={handleInputChange} style={{
                fontSize: "17px", background: "#fff", marginTop: "10px"
              }}></input>
              <span onClick={() => {
                setDetails({ ...details, bookingId: "" })
                bookingidRef.current.value = "";
              }} style={{ position: "absolute", left: "95%", top: "30px", opacity: "0.7" }}>{details.bookingId != "" && <img src="images/icons/closeIcon.svg" />}</span>
              {errMsgValid.isBookingId && <div style={{ color: "red", fontSize: "17px" }}>Enter Valid Booking Id</div>}
            </div>
            {isExperiencer? <><div className="input-group2">
              
              <label className={details.reason && disabled ? "contactusblue" : disabled ? "contactuslabel" : "contactusnotlabel"} style={{ fontWeight: "400", fontSize: "17px" }}>Reason for Submission</label>
              < input readOnly name="reason" placeholder="Select Reason" value={details.reason} ref={reasonRef} onChange={handleInputChange} autoComplete="off" className={!details.reason ? "contactselect" : "contactselected"} style={{
                fontSize: "17px", marginTop: "10px"
              }} onClick={() => { setSelectOpen(prev => !prev) }} />
              <span onClick={() => {
                setDetails({ ...details, reason: "" })
                reasonRef.current.value = "";
              }} style={{ position: "absolute", left: "95%", opacity: "0.7", top: "30px" }} className="crosslastname">{details.reason != "" ? <img style={{ top: "30px" }} onClick={() => { setSelectOpen(false) }} src="images/icons/closeIcon.svg" /> : <img src={downarrow} onClick={() => { setSelectOpen(prev => !prev) }} style={{ top: "55px" }} />}</span>


            </div>
            
          {selectOpen &&
            <div className="selectMenu" style={{ position: "absolute" }} >
              <Card className="cardmenu">
                <div class="dropdown-item1" onClick={() => {
                  setDetails({ ...details, reason: "Complaint" })
                  setSelectOpen(false)
                }}>Complaint</div>
                <div class="dropdown-item1" onClick={() => { setDetails({ ...details, reason: "Refund" }); setSelectOpen(false) }}>Refund</div>
                <div class="dropdown-item1" onClick={() => { setDetails({ ...details, reason: "Appeal Platform Suspension" }); setSelectOpen(false) }}>Appeal Platform Suspension</div>
                <div class="dropdown-itemlast" onClick={() => { setDetails({ ...details, reason: "Other" }); setSelectOpen(false) }}>Other</div>
              </Card>
            </div>}
            
            </>: <> <div className="input-group2">
              
              <label className={details.reason && disabled ? "contactusblue" : disabled ? "contactuslabel" : "contactusnotlabel"} style={{ fontWeight: "400", fontSize: "17px" }}>Reason for Submission</label>
              < input readOnly name="reason" placeholder="Select Reason" value={details.reason} ref={reasonRef} onChange={handleInputChange} autoComplete="off" className={!details.reason ? "contactselect" : "contactselected"} style={{
                fontSize: "17px", marginTop: "10px"
              }} onClick={() => { setSelectOpen(prev => !prev) }} />
              <span onClick={() => {
                setDetails({ ...details, reason: "" })
                reasonRef.current.value = "";
              }} style={{ position: "absolute", left: "95%", opacity: "0.7", top: "30px" }} className="crosslastname">{details.reason != "" ? <img style={{ top: "30px" }} onClick={() => { setSelectOpen(false) }} src="images/icons/closeIcon.svg" /> : <img src={downarrow} onClick={() => { setSelectOpen(prev => !prev) }} style={{ top: "55px" }} />}</span>


            </div> 
          {selectOpen &&
            <div className="selectMenu" style={{ position: "absolute" }} >
              <Card className="cardmenu">
                <div class="dropdown-item1" onClick={() => {
                  setDetails({ ...details, reason: "Complaint" })
                  setSelectOpen(false)
                }}>Complaint</div>
                <div class="dropdown-item1" onClick={() => { setDetails({ ...details, reason: "Payout" }); setSelectOpen(false) }}>Payout</div>
                <div class="dropdown-item1" onClick={() => { setDetails({ ...details, reason: "Appeal Platform Suspension" }); setSelectOpen(false) }}>Appeal Platform Suspension</div>
                <div class="dropdown-itemlast" onClick={() => { setDetails({ ...details, reason: "Appeal Blocked Decision" }); setSelectOpen(false) }}>Appeal Blocked Decision</div>
              </Card>
            </div>}  </>}
            </div>

          <div className="input-row">
            <div className="input-group">
              <label className={errMsgValid.isZipCode ? "contactusred" : details.zipCode && disabled ? "contactusblue" : disabled ? "contactuslabel" : "contactusnotlabel"} style={{ fontWeight: "400", fontSize: "17px" }}>Zip Code</label>
              <input type="text" autoComplete="off" placeholder="44946" name="zipCode" ref={zipcodeRef} maxLength={5} onChange={handleInputChange} style={{ fontSize: "17px", marginTop: "10px", background: "#fff" }}></input>
              <span onClick={() => {
                setDetails({ ...details, zipCode: "" })
                zipcodeRef.current.value = "";
              }} style={{ position: "absolute", left: "96%", opacity: "0.7", top: "30px" }}>{details.zipCode != "" && <img src="images/icons/closeIcon.svg" />}</span>
              {errMsgValid.isZipCode && <div style={{ color: "red", fontSize: "17px", marginLeft: "-7px" }}>*InValid ZipCode</div>}
            </div>
            <div className="input-group2">
              <label className={details.message && disabled ? "contactusblue" : disabled ? "contactuslabel" : "contactusnotlabel"} style={{ fontWeight: "400", fontSize: "17px" }}>Message</label>
              <input type="text" autoComplete="off" placeholder="Write Something......" ref={messageRef} name="message" onChange={handleInputChange} style={{
                fontSize: "17px", marginTop: "10px"
              }}></input>
              <span onClick={() => {
                setDetails({ ...details, message: "" })
                setDetails({ ...details, message: "" })
                messageRef.current.value = "";
              }} style={{ position: "absolute", left: "95%", opacity: "0.7", top: "30px" }} className="crosslastname">{details.message != "" && <img src="images/icons/closeIcon.svg" />}</span>
            </div>

          </div>
          <button type="submit" disabled={disabled} className={disabled ? "disablebtn" : "enablebtn"} style={{ width: "100%", backgroundColor: "#4189DD", padding: "10px", border: "none", outline: "none", borderRadius: "5px", color: "#fff", fontSize: "18px", margin: "5px 20px 20px 5px", fontColor: "Poppins", marginTop: "20px" }}>Submit</button>

        </form>
      </div>


      <Footer />

    </>
  )

}
export default ContactUs;