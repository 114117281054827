import React, { useState, useEffect } from "react";
import "./Header.css";
import wareeg from "../../assets/wareeg/wareeg.svg";
import { motion } from "framer-motion";
import {isIOS,isAndroid,isMobile} from 'react-device-detect';
import { Link } from "react-router-dom";
import Modal from 'react-modal'
//import {Modal} from 'react-bootstrap'
import ScannerModal from "./ScannerModal";
import { useNavigate } from "react-router-dom";



const Header = (props) => {
  const [isWareegImageClicked, setIsWareegImageClicked] = useState(false);
  const [isHeaderButtonClicked, setIsHeaderButtonClicked] = useState(false);
  const [deviceType,setDeviceType]=useState("default");
  const [modalIsOpen, setIsOpen] =useState(false)
  const [showModal,setShowModal]=useState(false);
  const navigate=useNavigate();
  //const [hideModal,setHideModal]=useState(false);
  useEffect(()=>{
    if(isAndroid)
    {
      setDeviceType("isAndroid")
    }
    else if(isIOS && isMobile)
    {
      setDeviceType("isIOS")
    }
  },[])
  const showScanModal=()=>{
    setShowModal(true);
  }
  const hideScanModal=()=>{
    setShowModal(false)
  }
  const closeModal=()=>{
    setIsOpen(false)
  }
  const openModal=()=>{
    setIsOpen(true)
  }
  const headerButtonClickHandler = () => {
    props.setIsHeaderButtonClicked(true);
    setIsHeaderButtonClicked(true);
    setTimeout(() => props.setIsHeaderButtonClicked(false), 1000);
    setTimeout(() => setIsHeaderButtonClicked(false), 100);
  };
  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      height:'392px',
      width:"560px",
      backgroundColor:"white",
      borderRadius:"30px",
      overflow:"hidden",
      padding:'5px',
      padding:"20px"
    },
  };
  const scanHandler=()=>{
    setShowModal(true);
    
  }
  return (
    <>
    <div className="container-fluid headerContainer">
      <div className="row justify-content-between headerWareeg">
        <div className="col-6 align-self-center">
          <img
            onClick={() => {
              navigate("/");
              setIsWareegImageClicked(true);
              window.location.reload(false);
              setTimeout(() => {
                setIsWareegImageClicked(false);
              }, 2000);
              // localStorage.clear();
           
            }}
            className={`headerImage ${
              isWareegImageClicked && "headerImageClicked"
            }`}
            src={wareeg}
            alt="name_wareeg"
          />
        </div>
        <div className="col-6 d-flex justify-content-end align-self-center">
          <motion.button
            animate={{ opacity: isHeaderButtonClicked ? 0.2 : 1 }}
            // transition={{ type: "tween", duration: 0.5 }}
            className="earlyAccessButton"
            // onClick={headerButtonClickHandler}
          >
            { deviceType==="isAndroid"?<Link style={{textDecoration:"none"}}  target="_blank" to="https://play.google.com/store/apps/">
                Get Wareeg<img className="scannerimg" style={{marginLeft:"10px"}}src="/images/icons/maskgroup.svg" />
              </Link>:deviceType==="isIOS"?<Link style={{textDecoration:"none"}} target="_blank" to="http://appstore.com/">
                Get Wareeg<img className="scannerimg" style={{marginLeft:"10px"}}src="/images/icons/maskgroup.svg" />
              </Link>:<span onClick={openModal}>Get Wareeg<img className="scannerimg" style={{marginLeft:"10px"}}src="/images/icons/maskgroup.svg" /></span>}
           
            {/* <button className="earlyAccessButton"> Get Early Access</button> */}
            {/* <span className="earlyAccessSpan">Get Early Access</span> */}
            {/* Get Wareg */}
            {/* </Tappable> */}
          </motion.button>
          {/* <span style={{width:"30px",height:"30px",marginLeft:"20px",marginTop:"12px"}}><img style={{width:"100%",height:"100%"}} src="/images/icons/scannerwareg.png"/></span> */}
        </div>
      </div>
    </div>
    {/* <ScannerModal isHidden={showScanModal} onClose={hideScanModal} /> */}
    {/* {console.log(showScanModal)} */}
    {/* <Modal show={showModal} onHide={hideScanModal}> 
        <Modal.Header closeButton>
    <Modal.Title>Translations</Modal.Title>
  </Modal.Header>
  <Modal.Body>
    <h4>Filter:</h4>
    <p>Duis mollis, est non commodo luctus, nisi erat porttitor ligula.</p>
    <hr />
  </Modal.Body>
  <Modal.Footer>
    <button onClick={hideScanModal}>Close</button>
  </Modal.Footer>
        </Modal> */}
        {/* <button onClick={openModal}>Open Modal</button> */}
      <Modal
        isOpen={modalIsOpen}
        //onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="QR CODE"//<img src="/images/icons/closeIcon.svg"/>
      >
    <span><img onClick={closeModal} style={{width:"25px",height:"25px",marginLeft:"511px",cursor:"pointer"}}src="/images/icons/closeIcon.svg"/></span> 
    <div  style={{display:"flex",flexDirection:"row",justifyContent:"space-between"}}>
      <div>
       <div style={{width:"200px",height:"200px",display:"flex",justifyContent:"center",alignItems:"center"}}><img src="/images/icons/appleplaystore.png" style={{width:"100%",height:"100%"}}/></div>
       <p style={{fontSize:"20px",fontWeight:"700",color:"#000",textAlign:"center"}} >Scan In  IOS  </p>
       </div>
       <div>
       <div style={{width:"200px",height:"200px",display:"flex",justifyContent:"center",alignItems:"center"}}><img src="/images/icons/playstorefile.png" style={{width:"100%",height:"100%"}}/></div>
       <p style={{fontSize:"20px",fontWeight:"700",color:"#000",textAlign:"center"}} >Scan In  Andriod  </p>
       </div>
       {/* <div>
       <div style={{width:"385.3px",height:"319.3px",display:"flex",justifyContent:"center",alignItems:"center"}}><img src="/images/icons/scannerimgboth.png" style={{width:"100%",height:"100%"}}/></div>
       {/* <p  style={{fontSize:"20px",fontWeight:"700",color:"#000",textAlign:"center"}} >Scan In Andriod  </p> */}
       {/* </div> */} 
        </div>  <p style={{textAlign:"center",color:"#000",fontWeight:"bold",fontSize:"26px",padding:"0 24px"}}>Scan the QR Code and Get Wareeg App</p>
       {/* <div onClick={closeModal} style={{cursor:"pointer",fontSize:"20px",color:"#fff",marginTop:"10px",textAlign:"right"}} ><button style={{border:"none",outline:"none",backgroundColor:"transparent"}}>Close</button></div> */}
      </Modal>
    </>
  );
};
export default Header;
