import CancellationPol from "../Components/Policies/CancellationPol";
import Header from "../Components/Header/Header";
import Footer from "../Components/Footer/Footer";
const Cancellation=()=>{
    return(
        <>
        <Header/>
        <div style={{padding:"3% 7%"}}>
        <div className="headingterms" style={{fontWeight:"bold",color:"#061A32",fontFamily:"Poppins",marginBottom:"20px"}} >Cancellation Policy</div>
        <CancellationPol/>
        </div>
        <Footer/>
        </>
    )

}
export default Cancellation;