import React from "react";
import "./Experiencer.css";
import browse from "../../../assets/sliderDiv/experiencer/browse.svg";
import book from "../../../assets/sliderDiv/experiencer/book.svg";
import experience from "../../../assets/sliderDiv/experiencer/experiencer.svg";
// import { LazyLoadImage } from "react-lazy-load-image-component";
const Experiencer = () => {
  return (
    <div className="container experiencerContainer">
      <div className="row eachContent d-flex ">
        <div className="col-1">
          <img className="browse" src={browse} alt="browse_button" />
        </div>
        <div className="col-11 px-2 para">
          <p className="paraHeading">Browse</p>
          <p className="paraContent">
            Explore destinations through locals and refine your search by
            selecting a timeframe, interests, and preferred language for a
            personalized experience.
          </p>
        </div>
      </div>
      <div className="row eachContent">
        <div className="col-1">
          <img className="browse" src={book} alt="book_button" />
        </div>
        <div className="col-11 px-2 para">
          <p className="paraHeading">Book</p>
          <p className="paraContent">
            Book local hosts who share your interests, anywhere and anytime,
            with just a few clicks.
          </p>
        </div>
      </div>
      <div className="row eachContent">
        <div className="col-1">
          <img className="browse" src={experience} alt="experience_button" />
        </div>
        <div className="col-11 px-2 para">
          <p className="paraHeading">Experience</p>
          <p className="paraContent">
            Embark on a personalized and unique adventure with your local, from
            sunrise hikes to nightlife and everything in between.
          </p>
        </div>
      </div>
    </div>
  );
};
export default Experiencer;
