import React, { useState, useEffect } from "react";
import classes from "./Calculator.module.css";
import InputSelector from "./InputSelector";
import AmountDisplay from "./AmountDispaly";

const Calculator = () => {
  
  const [hours, setHours] = useState(1);
  const [people, setPeople] = useState(1);
  let [wareegAmount, setWareegAmount] = useState(26.67);
  let [otherAmount, setOtherAmount] = useState(29.67);
 
  useEffect(() => {
    let amountForOne = 26.67 * Number(hours);
    let amountForMorePeople =
      26.67 * Number(hours) + (Number(people) - 1) * 18.75;
    setOtherAmount((29.67 * Number(hours) * Number(people)).toFixed(2));
    if (people === 1) setWareegAmount(amountForOne.toFixed(2));
    else setWareegAmount(amountForMorePeople.toFixed(2));
  }, [people, hours]);
  
  return (
    <div className={classes.container}>
      <div className={classes.calculator}>
        <InputSelector
          setHours={(val) => setHours(val)}
          setPeople={(val) => setPeople(val)}
          hours={hours}
        />
        <div className={classes.bottomDiv}>
          <div className={classes.amountDisplay}>
            <AmountDisplay
              wareegAmount={wareegAmount}
              otherAmount={otherAmount}
            />
          </div>
          <div className={classes.disclaimer}>
            Disclaimer: Our cost comparison does not include taxes and fees, and
            is based on publicly available information which may vary.
          </div>
        </div>
      </div>
    </div>
  );
};

export default Calculator;
