import './refundpolicy.css'
export const RefundPolicy=()=>{
    return(
        <>
        <div className="refundpolicy">
        {/* <p class="s1" style={{paddingTop: "5pt",paddingLeft: "5pt",textIndent: "0pt",textAlign: "left"}}>Wareeg Refund Policy</p> */}
        <p className="extraspace" style={{paddingTop: "10pt",paddingLeft: "5pt",textIndent: "0pt",textAlign: "justify"}}>At Wareeg, we strive to ensure that all experiences booked through our platform meet the expectations of our Experiencers. However, we understand that there may be situations where a refund is necessary. To ensure a fair and consistent policy for all parties, we have established the following guidelines:</p>
        <h4 className="extraheading1" style={{paddingTop: "8pt",paddingLeft: "5pt",textIndent: "0pt",textAlign: "left",color:"#4189DD",fontSize:"18px",fontWeight:"600"}}>Refund Requests:</h4>
        <p className="extraspace" style={{paddingTop: "9pt",paddingLeft: "5pt",textIndent: "0pt",textAlign: "justify"}}>If an Experiencer faces cancellations or no-shows while booking an experience through Wareeg, they may be eligible for a refund. To check the status of their refund, Experiencers can submit a submission through wareeg.io.</p>
        <h4 className="extraheading1" style={{paddingTop: "8pt",paddingLeft: "5pt",textIndent: "0pt",textAlign: "left",color:"#4189DD",fontSize:"18px",fontWeight:"600"}}>Grounds for Refund:</h4>
        <p className="extraspace" style={{paddingTop: "9pt",paddingLeft: "5pt",textIndent: "0pt",textAlign: "justify"}}>We will consider refund requests based on the following grounds:</p>
        <ul id="l1" className="extraspace">
            <li >
                <p className="extraspace1" style={{paddingLeft:"41pt",textIndent: "-18pt",textAlign: "left"}}><span style={{ width:"5px",
                height:"5px",
                display:"flex",
                alignItems:"top",
                opacity:"0.8",
                display:"inline-block",
                borderRadius: "50%",
                backgroundColor:"#6b6b6b" ,
                position:"relative",
                top:"-2px",
                marginRight: "5px"}} className="extradot"></span>The experiencer cancels a reservation up to 24 hours before the start time.</p>
            </li>
            <li >
                <p  className="extraspace1" style={{paddingLeft: "41pt",textIndent: "-18pt",textAlign: "left"}}><span style={{ width:"5px",
                height:"5px",
                display:"flex",
                alignItems:"top",
                opacity:"0.8",
                display:"inline-block",
                borderRadius: "50%",
                backgroundColor:"#6b6b6b" ,
                postion:"relative",
                top:"-2px",
                marginRight: "5px"}} className="extradot"></span>The local did not show up for the scheduled experience.</p>
            </li>
            <li >
                <p className="extraspace1" style={{paddingTop: "1pt",paddingLeft: "41pt",textIndent: "-18pt",textAlign: "left"}}><span style={{ width:"5px",
                height:"5px",
                display:"flex",
                alignItems:"top",
                opacity:"0.8",
                display:"inline-block",
                borderRadius: "50%",
                backgroundColor:"#6b6b6b" ,
                position:"relative",
                top:"-2px",
                marginRight: "5px"}} className="extradot"></span>The local canceled the experience before the start time.</p>
            </li>
        </ul>
        <h4 className="extraheading1" style={{paddingTop: "9pt",paddingLeft: "5pt",textIndent: "0pt",textAlign: "left",color:"#4189DD",fontSize:"18px",fontWeight:"600"}}>Refund Amount:</h4>
        <p className="extraspace"  style={{paddingTop: "9pt",paddingLeft: "5pt",textIndent: "0pt",textAlign: "justify"}}>If we determine that an Experiencer is entitled to a refund, we will issue a refund for the full amount paid minus any fees charged by Wareeg.</p>
        <h4 className="extraheading1" style={{paddingTop: "8pt",paddingLeft: "5pt",textIndent: "0pt",textAlign: "left",color:"#4189DD",fontSize:"18px",fontWeight:"600"}}>Refund Processing:</h4>
        <p className="extraspace" style={{paddingTop: "9pt",paddingLeft: "5pt",textIndent: "0pt",textAlign: "justify" }}>Refunds will be processed via the payment method used during booking, with processing times varying due to various factors.</p>
        <p className="extraspace" style={{paddingTop: "7pt",paddingLeft: "5pt",textIndent: "0pt",textAlign: "justify"}}>Wareeg reserves the right to make changes to this Refund Policy at any time and for any reason, with or without notice. By using Wareeg, you agree to this refund policy and any changes made in the future.</p>
        </div>
        </>
    )

}
export default RefundPolicy;